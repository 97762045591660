import React from 'react'
import { useSelector } from 'react-redux'
import { userDataSelector } from '../../storage/reducers/user.reducer'
import { SuperUserDashboard } from './su.dashboard'
import { PMDashboard } from './pm.dashboard'
import { CommonDashboard } from './common.dashboard'
import { isDev, isPM } from '../../helpers'

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
    const user = useSelector(userDataSelector)
    let dashboard = <></>

    switch(user?.position){
        case 'SU':
            dashboard = <SuperUserDashboard/>           
            break;
        case 'Delivery Manager':
            dashboard = <PMDashboard/>           
            break;
        case 'PM':
            dashboard = <PMDashboard/>
            break;
        case 'DEV':
            dashboard = <CommonDashboard userPosition='DEV'/>
            break;
        case 'QA':
            dashboard = <CommonDashboard userPosition='QA'/>
            break;
        default:
            if (isDev(user?.position)) {
                dashboard = <CommonDashboard userPosition='DEV'/>
            } else if (isPM(user?.position)) {
                dashboard = <PMDashboard/>
            }
    }

    return <div>
        {dashboard}
    </div>
}

export { Dashboard }