import React from 'react'
import { Popover } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';

const IssuePopover = ({ record }) => {

  const getContent = (record) => {
    return (
      <div>
        {record?.issues?.length
          ? record.issues.map((issue) => <p key={issue}>{issue}</p>)
          : null}
      </div>
    );
  };

  return (
    <Popover
    content={getContent(record)}
    title='Issues'
    trigger='hover'
  >
    <WarningTwoTone twoToneColor='#ff2c00' />
  </Popover>
  )
}

export default IssuePopover