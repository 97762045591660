import { Progress, Typography, Row, Col } from 'antd';
import moment from 'moment';

const { Text } = Typography;

const RatioBar = ({ estimate = 0, spent = 0, type = '' }) => {
  let progress = 0;
  if (type === 'milestone') {
    estimate = Math.round(estimate);
    spent = Math.round(spent);
  }
  
  if (estimate && spent) {     
    progress = Math.round(Math.abs(spent / estimate) * 100);
  }

  return (
    <div>
      <Row style={{ fontSize: '12px' }}>
        <Col span={8}>
          {type === 'task' && (
            <Text type='secondary'>{moment.utc(spent).format('HH:mm')}</Text>
          )}
          {type === 'milestone' && <Text type='secondary'>{spent}h</Text>}
        </Col>
        <Col span={8}></Col>
        <Col span={8}>
          {type === 'task' && (
            <Text type='secondary'>{moment.utc(estimate).format('HH:mm')}</Text>
          )}
          {type === 'milestone' && <Text type='secondary'>{estimate}h</Text>}
        </Col>
      </Row>
      {progress > 100 ? (
        <Progress
          percent={progress - 100}
          size='small'
          status='exception'
          format={(percent) => {
            if (type === 'task') {
              return (
                <>
                  {progress}% <br /> +{((spent - estimate) / 60 / 60 / 1000).toFixed(2)}h
                </>
              );
            } else if (type === 'milestone') {
              return (
                <>
                  {progress}% <br /> +{moment(spent - estimate).format('HH:mm')}
                </>
              );
            }
          }}
        />
      ) : (
        <Progress
          percent={progress}
          size='small'
          format={(percent) => `${percent}%`}
        />
      )}
    </div>
  );
};

export default RatioBar;
