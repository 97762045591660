import { Statistic, Col } from 'antd';
import { BugOutlined } from '@ant-design/icons';

interface RequireQAWidgetProps {
  count: number;
}

const RequireQAWidget: React.FC<RequireQAWidgetProps> = ({ count }) => {
  return (
    <>
      {count ? (
        <Col span={12}>
          <Statistic
            title='Require QA'
            value={count}
            prefix={<BugOutlined />}
          />
        </Col>
      ) : null}
    </>
  );
};

export default RequireQAWidget;
