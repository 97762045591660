import React from "react";
import { useSelector } from "react-redux";
import { userDataSelector } from "../../storage/reducers/user.reducer";
import { Menu } from "antd";
import {
  VideoCameraOutlined,
  UploadOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  DollarOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { useNavigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../constants/router.constants";

interface MenuProps {}

const MenuComp: React.FC<MenuProps> = () => {
  const user = useSelector(userDataSelector);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[pathname]}
        onClick={(item) => {
          navigate(item.key);
        }}
      >
        <Menu.Item key="/dashboard" icon={<UserOutlined />}>
          Dashboard
        </Menu.Item>
        <Menu.Item key="/profile" icon={<VideoCameraOutlined />}>
          Profile
        </Menu.Item>
        {/* {(user?.position === 'SU' || user?.position === "Delivery Manager") ? (
          <Menu.Item key='/sales' icon={<DollarOutlined />}>
            Sales Dashboard
          </Menu.Item>
        ) : null} */}
        {user && ["SU", "HR", "Delivery Manager"].includes(user.position) ? (
          <Menu.Item key="/members" icon={<UsergroupAddOutlined />}>
            Members
          </Menu.Item>
        ) : null}
        {["SU", "Delivery Manager"].includes(user?.position || "") ? (
          <Menu.Item key={ROUTES.REPORTS} icon={<ExclamationCircleOutlined />}>
            Reports
          </Menu.Item>
        ) : null}
      </Menu>
    </>
  );
};

export { MenuComp };
