import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

export interface MembersData {
  [key: string]: any
}

export interface MembersStateData {
  data?: MembersData[];
}

const initialState: MembersStateData = {
  data: undefined,
};

export const MembersSlice = createSlice({
  name: 'members',
  initialState: initialState,
  reducers: {
    set: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    },
  },
});

export const $members_actions = MembersSlice.actions;

export const membersSelector = (state: RootState): MembersStateData => state.members;

export const membersDataSelector = createSelector(
  membersSelector,
  (state) => state.data
);

export default MembersSlice.reducer;
