import moment from 'moment';

export const getTimeConstants = () => {
  const initTC = {
    today: moment().startOf('day').valueOf(),
    yesterday: moment().startOf('day').valueOf() - 86400000,
    thisWeek: moment().startOf('week').valueOf() + 86400000,
    lastWeek: moment().startOf('week').valueOf() - 518400000,
    thisMonth: moment().startOf('month').valueOf(),
    lastMonth: moment().startOf('month').subtract(1, 'months').valueOf(),
    thisPeriod:
      moment().date() > 14
        ? moment().startOf('month').valueOf() + 1209600000
        : moment().startOf('month').valueOf(),
    lastPeriod:
      moment().date() > 14
        ? moment().startOf('month').valueOf()
        : moment().startOf('month').subtract(1, 'months').valueOf() +
          1209600000,
  };
 
  return initTC;
};

export const getHoursInPeriod = () => {
  const hoursPeriod = {
    today: 8,
    yesterday: 8,
    thisWeek: 40,
    thisPeriod:
      moment().date() > 14 ? (moment().daysInMonth() - 14) * 8 : 14 * 8,
    lastPeriod:
      moment().date() > 14
        ? 14 * 8
        : (moment().subtract(1, 'months').daysInMonth() - 14) * 8,
  };

  return hoursPeriod;
};

interface TimeLog {
  duration: number;
  title: string;
}

interface TimeLogs {
  today: TimeLog | null;
  yesterday: TimeLog | null;
  thisWeek: TimeLog | null;
  thisPeriod: TimeLog | null;
  lastPeriod: TimeLog | null;
}

export const getTimeLogs = (timelogs, timersList) => {
  const timersConst = getTimeConstants();

  const newTimeLogs: TimeLogs = {
    today: null,
    yesterday: null,
    thisWeek: null,
    thisPeriod: null,
    lastPeriod: null,
  };

  timersList.forEach((str) => {
    switch (str) {
      case 'today':
        newTimeLogs.today = {
          duration: 0,
          title: 'Today',
        };
        break;
      case 'yesterday':
        newTimeLogs.yesterday = {
          duration: 0,
          title: 'Yesterday',
        };
        break;
      case 'thisWeek':
        newTimeLogs.thisWeek = {
          duration: 0,
          title: 'This Week',
        };
        break;
      case 'thisPeriod':
        newTimeLogs.thisPeriod = {
          duration: 0,
          title: 'This Period',
        };
        break;
      case 'lastPeriod':
        newTimeLogs.lastPeriod = {
          duration: 0,
          title: 'Last Period',
        };
        break;

      default:
        break;
    }
  });

  timelogs?.length &&
    timelogs.forEach((timeLog) => {
      if (newTimeLogs?.today && Number(timeLog.start) > timersConst.today) {
        newTimeLogs.today.duration += timeLog.duration;
      } else if (
        newTimeLogs?.yesterday &&
        Number(timeLog.start) > timersConst.yesterday &&
        Number(timeLog.start) <= timersConst.today
      ) {
        newTimeLogs.yesterday.duration += timeLog.duration;
      }

      if (
        newTimeLogs?.thisWeek &&
        Number(timeLog.start) > timersConst.thisWeek
      ) {
        newTimeLogs.thisWeek.duration += timeLog.duration;
      }

      if (
        newTimeLogs?.thisPeriod &&
        Number(timeLog.start) > timersConst.thisPeriod
      ) {
        newTimeLogs.thisPeriod.duration += timeLog.duration;
      } else if (
        newTimeLogs?.lastPeriod &&
        Number(timeLog.start) > timersConst.lastPeriod &&
        Number(timeLog.start) <= timersConst.thisPeriod
      ) {
        newTimeLogs.lastPeriod.duration += timeLog.duration;
      }
    });

  return newTimeLogs;
};
