import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import Project from '../../types/Project';

export interface ProjectsStateData {
  data?: Project[];
}

const initialState: ProjectsStateData = {
  data: undefined,
};

export const ProjectsSlice = createSlice({
  name: 'projects',
  initialState: initialState,
  reducers: {
    set: (state, action: PayloadAction<Project[]>) => {
      state.data = action.payload;
    },
  },
});

export const $projects_actions = ProjectsSlice.actions;

export const projectsSelector = (state: RootState): ProjectsStateData => state.projects;

export const projectsDataSelector = createSelector(
  projectsSelector,
  (state) => state.data
);

export default ProjectsSlice.reducer;
