import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { API } from '../../helpers/api';

const ReturnSU = () => {
  const jwtSU = window.localStorage.getItem('su-jwt');
  const navigate = useNavigate();

  const handleBackSU = () => {
    API._setBearer();
    jwtSU && window.localStorage.setItem('jwt', jwtSU);
    window.localStorage.removeItem('su-jwt')
    navigate('/members')
    location.reload()
  };

  return (
    <>
      {jwtSU ? (
        <Button type='primary' onClick={handleBackSU}>
          back to SU
        </Button>
      ) : null}
    </>
  );
};

export default ReturnSU;
