import React, { useEffect, useState } from "react";
import { Button, Row, message } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { API } from "../helpers/api";
import { useSelector } from "react-redux";
import { internalDataSelector } from "../storage/reducers/internal.reducer";
import moment from "moment";

const CHECK_TIMING = 20 * 1000;

const Reload = () => {
  const [isRealoading, setReloaded] = useState<boolean>(false);
  const internalData = useSelector(internalDataSelector);

  const handleSyncFinished = () => {
    setReloaded(false);
    window.location.reload();
  };

  const checkIfSyncFinished = async () => {
    API.getSyncData()
      .then((res) => {
        if (res?.data?.isSyncRunning) {
          setTimeout(() => {
            checkIfSyncFinished();
          }, CHECK_TIMING);
        } else {
          handleSyncFinished();
        }
      })
      .catch(() => {});
  };

  const togleReload = async () => {
    setReloaded(true);

    API.reloadTasks().catch((err) => {
      message.error(err.response.data?.error.message);
    });

    // top request could take so long
    // so we check every {CHECK_TIMING} if it's finished
    setTimeout(() => {
      checkIfSyncFinished();
    }, 1000);
  };

  return (
    <Row style={{ alignItems: "center", marginRight: "20px" }}>
      {internalData.lastSynced
        ? "Last synced: " +
          moment(internalData.lastSynced).format("ddd DD, HH:mm")
        : null}
      {/* <Row> */}
      <Button
        style={{ marginLeft: "10px" }}
        icon={
          <ReloadOutlined
            spin={isRealoading ? true : false}
            onClick={togleReload}
          />
        }
      />
      {/* </Row> */}
    </Row>
  );
};

export default Reload;
