import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { useMapDispatch } from '../../hooks/utils.hooks';
import { $user_actions } from '../../storage/reducers/user.reducer';
import { API } from '../../helpers/api';
import { useSelector } from 'react-redux';
import { Table, Button, Tabs } from 'antd';
import { userDataSelector } from '../../storage/reducers/user.reducer';
import { milestonesDataSelector } from '../../storage/reducers/milestones.reducer';
import { tasksDataSelector } from '../../storage/reducers/tasks.reducer';
import { MembersData, membersDataSelector } from '../../storage/reducers/members.reducer';
import expandedCommonTable from '../../components/common/expandedCommonTable';
import getColumns from '../../components/common/getColumns';
import {
  AMOUNT_OF_MILESTONES,
  AMOUNT_OF_OPEN_TASKS,
  AMOUNT_OF_REVIEW_TASKS,
  AMOUNT_OF_TASKS_TO_CLOSE,
  LOGS, 
  MEMBER_NAME,
} from '../../constants/columnConstants';
import { getTimeLogs } from '../../helpers/timeLogs';
import { isDev, isPM } from '../../helpers';
import _ from 'underscore';
import { ReportProjectsData } from '../reports';

const { TabPane } = Tabs;

interface User {
  [key: string]: string;
}

interface MembersProps {
  reportProjectsData?: ReportProjectsData
}

interface Task {
  [key: string]: string;
}
interface Tasks {
  [index: number]: Task;
}
interface Milestone {
  [key: string]: string;
}

interface Milestones {
  [key: number]: Milestone;
}
interface Member {
  activeMilestones: Milestones;
  activeTasks: Tasks;
}

interface Members {
  [index: number]: Member;
}

interface GroupedMembers {
  [index: string]: any;
}

const Members: React.FC<MembersProps> = ({ reportProjectsData }) => {
  const user = useSelector(userDataSelector);
  const handleSet = useMapDispatch($user_actions.set);
  const milestones: any = reportProjectsData?.filteredMilestones || useSelector(milestonesDataSelector);
  const tasks: any = reportProjectsData?.filteredTasks || useSelector(tasksDataSelector);
  const members = (reportProjectsData?.filteredMembers as unknown as MembersData) || useSelector(membersDataSelector);
  const [newMembers, setMembers] = useState<Members | any>([]);
  const [groupedMembers, setGroupedMembers] = useState<GroupedMembers>({}); 
  const navigate = useNavigate()
  
  useEffect(() => {
    if (members && tasks && milestones) {  
      const newMembers = [...Object.values(members)].map((memb) => {
        let newMiles = [];
        if (memb.activeMilestones?.length) {
          newMiles = memb.activeMilestones.map((name) => {
            return milestones[name];
          });
        }

        let newTasks = [];

        if (memb.activeTasks?.length) {
          newTasks = memb.activeTasks.map((name) => {
            return tasks[name];
          });
        }

        const newTimeLogs = getTimeLogs(memb.timelogs, ['today', 'yesterday', 'thisPeriod', 'lastPeriod']);

        const newMemb = {
          ...memb,
          activeMilestones: newMiles,
          activeTasks: newTasks,
          timelogs: newTimeLogs,
        };
        return newMemb;
      });
      setMembers(newMembers);
      
      setGroupedMembers(
        _.groupBy(newMembers, 
          function(m){ 
            return m.pf_data.department?.name; 
          }
        )
      )
    }
  }, [members, milestones, tasks]);

  const devColumns = [
    ...getColumns([
      MEMBER_NAME,
      AMOUNT_OF_MILESTONES,
      AMOUNT_OF_OPEN_TASKS,
    ]),
    ...getColumns(reportProjectsData ? [] : [
      AMOUNT_OF_REVIEW_TASKS,
      LOGS,
    ]),
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => (
        <Button type='primary' onClick={() => handleSwitchUser(record)}>
          View
        </Button>
      ),
    },
  ];

  const pmColumns = [
    ...getColumns([
      MEMBER_NAME,
      AMOUNT_OF_MILESTONES,
      AMOUNT_OF_TASKS_TO_CLOSE,
    ]),
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => (
        <Button type='primary' onClick={() => handleSwitchUser(record)}>
          View
        </Button>
      ),
    },
  ];

  const handleSwitchUser= async (record: User) => {      
    
    const response = await API.getUserJWT(record.username).catch((err) => {
      message.error(err.response.data?.error.message);
    });

    if (response) {
      if (response.data.user) {
        handleSet(response.data.user);
        const jwtSU = window.localStorage.getItem('su-jwt');
        const jwt = window.localStorage.getItem('jwt');
        console.log('jwtSU', jwtSU);

        if (!jwtSU && jwt) {
          window.localStorage.setItem('su-jwt', jwt);
        }
        API._setBearer();
        window.localStorage.setItem('jwt', response.data.jwt);
        navigate('/dashboard')
        location.reload();
      }
    }
  };

  return (
    <>
      {user && ['SU', 'HR', 'Delivery Manager'].includes(user.position) ? (
        <>
          <Tabs type='card' defaultActiveKey='1'>

            {Object.keys(groupedMembers).map(key => 
              <TabPane tab={key === 'undefined' ? "Others" : key} key={key}>
                <Table
                  size='small'
                  pagination={false}
                  columns={['Management Department', 'Marketing Department'].includes(key) ? pmColumns : devColumns}
                  dataSource={[
                    ...groupedMembers[key]
                  ]}
                  expandable={{
                    expandedRowRender: (record) => 
                      ['Management Department', 'Marketing Department'].includes(key) ?
                        expandedCommonTable(record, 'PM', !!reportProjectsData) 
                        : expandedCommonTable(record, 'DEV', !!reportProjectsData)
                  }}
                  rowKey='id'
                />
              </TabPane>
            )}
            {/* <TabPane tab='Developers' key='1'>
              <Table
                size='small'
                pagination={false}
                columns={devColumns}
                dataSource={[
                  ...newMembers.filter((mem) => isDev(mem.position)),
                ]}
                expandable={{
                  expandedRowRender: (record) =>
                    expandedCommonTable(record, 'DEV'),
                }}
                rowKey='id'
              />
            </TabPane>
            <TabPane tab='PM' key='2'>
              <Table
                size='small'
                pagination={false}
                columns={pmColumns}
                dataSource={[
                  ...newMembers.filter((mem) => isPM(mem.position)),
                ]}
                expandable={{
                  expandedRowRender: (record) =>
                    expandedCommonTable(record, 'PM'),
                }}
                rowKey='id'
              />
            </TabPane> */}
          </Tabs>         
        </>
      ) : null}
    </>
  );
};

export { Members };
