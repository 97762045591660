const ROUTES = {
  MAIN: "",
  AUTH: "/auth",
  DASHBOARD: "dashboard",
  PROFILE: "profile",
  MEMBERS: "members",
  SALES: "sales",
  REPORTS: "reports",
  GOOGLE_CALLBACK: "/api/auth/callback/google",
};

export { ROUTES };
