const getStatusColor = (status: string) => {
  
  switch (status) {
    case 'to clarify':
      return '#B5BCC2';
    case 'in progress':
      return '#0231E8';
    case 'to fix':
      return '#F9D900';
    case 'to do':
      return '#81B1FF';
    case 'fix mr':
      return '#FF7FAB';
    case 'qa':
      return '#7C4DFF';
    case 'done':
      return '#6BC950';
    case 'code review':
      return '#E65100';
    default:
      return '#B5BCC2';
  }
};

export default getStatusColor;
