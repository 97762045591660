import React, { useEffect } from 'react';
import { Card, Table, Tag, Typography } from 'antd';
import { hrsSelector } from '../../../storage/reducers/hrs.reducer';
import { useSelector } from 'react-redux';
import _ from 'underscore';

const { Link } = Typography;

enum positionColorMap {
  'Developer' = '#2ecc71',
  'QA' = '#992d22',
  'Manager' = '#e67e22',
  'Designer' = '#f1c40f',
  'HR' = '#e91e63',
  'Default' = '#b9bbbe',
}

const getColorByPosition = (position: string) => {
  if (position.includes('Developer')) return positionColorMap['Developer'];
  else if (position.includes('QA')) return positionColorMap['QA'];
  else if (position.includes('Manager') || position.includes('PM'))
    return positionColorMap['Manager'];
  else if (position.includes('Designer')) return positionColorMap['Designer'];
  else if (position.includes('HR')) return positionColorMap['HR'];
  else return positionColorMap['Default'];
};

const getCurrencyTypeSign = (currencyType: string): string => {
  switch (currencyType) {
    case 'USD':
      return '$';
    default:
      return '';
  }
};

interface HRTableProps {
  title: string;
}

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (name, record) => (
      <Link
        target='_blank'
        strong
        href={`https://app.clickup.com/2479620/t/${record.id}`}
      >
        {name}
      </Link>
    ),
  },
  {
    title: 'Position',
    dataIndex: 'position',
    key: 'position',
    render: (position) => {
      const color = getColorByPosition(position);
      return (
        <Tag color={color} key={position}>
          {' '}
          {position}
        </Tag>
      );
    },
  },
  {
    title: 'Salary',
    dataIndex: 'salary',
    key: 'salary',
    render: (salary) => (
      <div>
        <span>{salary.value && getCurrencyTypeSign(salary.currencyType)}</span>
        {salary.value}
      </div>
    ),
  },
];

const HRTable: React.FC<HRTableProps> = ({ title }) => {
  const candidats = useSelector(hrsSelector);
  const [preparedCandidatsData, setPreparedCandidatsData] = React.useState([]);
  useEffect(() => {
    setPreparedCandidatsData(_.map(candidats.data));
  }, [candidats]);
  return (
    <>
      {preparedCandidatsData?.length ? (
        <Card size='small' title={title}>
          <Table
            size='small'
            pagination={false}
            columns={columns}
            dataSource={preparedCandidatsData}
            rowKey='id'
            bordered
          ></Table>
        </Card>
      ) : null}
    </>
  );
};

export { HRTable };
