import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import Task from '../../types/Task';

export interface TasksStateData {
  data?: Task[];
  qaTasksCount: number;
}

const initialState: TasksStateData = {
  data: undefined,
  qaTasksCount: 0
};

export const TasksSlice = createSlice({
  name: 'tasks',
  initialState: initialState,
  reducers: {
    set: (state, action: PayloadAction<Task[]>) => {
      state.data = action.payload;
    },
    setQATasksCount: (state, action: PayloadAction<number>) => {
      state.qaTasksCount = action.payload;
    }
  },
});

export const $tasks_actions = TasksSlice.actions;

export const tasksSelector = (state: RootState): TasksStateData => state.tasks;

export const tasksDataSelector = createSelector(
  tasksSelector,
  (state) => state.data
);

export const qaTasksCountSelector = createSelector(
  tasksSelector,
  (state) => state.qaTasksCount
);

export default TasksSlice.reducer;
