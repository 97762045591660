import React from "react";
import { Tabs } from "antd";
import { MilestonesTableExtended } from "../../components/superuser/milestonesTableExtended";
import { ProjectsTable } from "../../components/superuser/projectsTable";
// import { LeadsTable } from "../../components/superuser/leads.table";
import { HRTable } from "../../components/tableComponents/tables/HRTable";
import { useSelector } from "react-redux";
import { qaTasksCountSelector } from "../../storage/reducers/tasks.reducer";
import RequireQAWidget from "../../components/superuser/require.qa.widget";
import { ClientsTable } from "../../components/superuser/clientsTable";
const { TabPane } = Tabs;

interface SUDashboardProps {
  isPM?: boolean;
}

const SuperUserDashboard: React.FC<SUDashboardProps> = ({ isPM }) => {
  const qaTasksCount: any = useSelector(qaTasksCountSelector);

  return (
    <Tabs type="card" defaultActiveKey="1">
      <TabPane tab="To Close Milestones" key="1">
        <MilestonesTableExtended
          isPM={isPM}
          title="To Close Milestones"
          type="to_close"
          forSU={true}
        />
      </TabPane>
      <TabPane tab="Active Milestones" key="2">
        <MilestonesTableExtended
          isPM={isPM}
          title="Active Milestones"
          type="active"
          forSU={true}
        />
      </TabPane>
      <TabPane tab="Closed Milestones" key="3">
        <MilestonesTableExtended
          isPM={isPM}
          title="Closed Milestones"
          type="closed"
          forSU={true}
        />
      </TabPane>
      <TabPane tab="Candidats" key="4">
        <HRTable title="Candidats" />
      </TabPane>
      {/* <TabPane tab="Sales Widget" key="5">
        <LeadsTable />
      </TabPane> */}
      <TabPane tab="QA Widget" key="6">
        <RequireQAWidget count={qaTasksCount} />
      </TabPane>
      <TabPane tab="Projects" key="7">
        <ProjectsTable isPM={isPM} title="Projects Table" />
      </TabPane>
      <TabPane tab="Clients" key="8">
        <ClientsTable title="Clients Table" />
      </TabPane>
    </Tabs>
  );
};

export { SuperUserDashboard };
