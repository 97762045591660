import React from 'react'
import { LayoutPage } from './layout/layout'

interface IMainPageProps {}

interface IMenu {
  collapsed: boolean
}

const MainPage: React.FC<IMainPageProps> = ( ) => {
    
    return (
    <div className={'root'}>
      <LayoutPage/>
    </div>
  )
}

export { MainPage }
