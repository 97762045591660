import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

export interface ListsData {}

export interface ListsStateData {
  data?: ListsData[];
}

const initialState: ListsStateData = {
  data: undefined,
};

export const ListsSlice = createSlice({
  name: 'lists',
  initialState: initialState,
  reducers: {
    set: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    },
  },
});

export const $lists_actions = ListsSlice.actions;

export const listsSelector = (state: RootState): ListsStateData => state.lists;

export const listsDataSelector = createSelector(
  listsSelector,
  (state) => state.data
);

export default ListsSlice.reducer;
