import { Table, Tag, Typography } from 'antd';
import {
  TASK_ASSIGNEES,
  TASK_NAME,
  TASK_STATUS,
  TASK_TIMELINE,
} from '../../constants/columnConstants';
import getColumns from '../common/getColumns';
import RatioBar from '../tableComponents/ratioBar';
import TimeStatistic from '../tableComponents/timeStatistic';

const { Link } = Typography;
interface Task {
  [key: string]: string;
}
interface Tasks {
  [index: number]: Task;
}
interface Record {
  id: string;
  tasks: any | Tasks;
  [key: string]: string;
  custom_fields: any;
}

const expandedTasksTable = (record: Record) => {
  const data: Task[] = [];

  record.tasks.length &&
    record.tasks.forEach((task) => {
      data.push({
        key: task.id ? task.id : '',
        taskId: task.id ? task.id : '',
        taskName: task.name ? `${task.name.substring(0, 40)}...` : '',
        taskAssignee: task.assignees?.clickup?.username
          ? task.assignees?.clickup?.username
          : '',
        status: task.status?.status ? task.status.status : '',
        statusColor: task.status?.color,
        estimate: task.estimate,
        spent: task.spent,
      });
    });

  const columns = 
  [
    {
      title: 'Task name',
      dataIndex: 'taskName',
      key: 'taskName',
      render: (taskName, record) => (
        <Link
          target='_blank'
          href={`https://app.clickup.com/t/${record.taskId}`}
        >
          {taskName}
        </Link>
      ),
    },
    {
      title: 'Task Assignee',
      dataIndex: 'taskAssignee',
      key: 'taskAssignee',
    },
    {
      title: 'Ratio',
      dataIndex: 'ratio',
      key: 'ratio',
      width: '180px',
      render: (et, record) => (
        <RatioBar
          estimate={record.estimate ? record.estimate : 0}
          spent={record.spent ? record.spent : 0}
          type='task'
        />
      ),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status, record) => {
        const color = record.statusColor || record.color || 'green';
        // switch (status) {
        //   case 'planing':
        //     color = 'volcano';
        // }
        return (
          <Tag color={color} key={status}>
            {' '}
            {status.toUpperCase()}{' '}
          </Tag>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={{
        y: 200,
      }}
    />
  );
};

export default expandedTasksTable;
