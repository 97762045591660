import React, { useEffect } from 'react';
import { Card, Space, Table, Typography, Tag } from 'antd';
import { milestonesActiveSelector } from '../../storage/reducers/milestones.reducer';
import { projectsDataSelector } from '../../storage/reducers/projects.reducer';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import { ExpandedMilestonesTable } from './expandedMilestonesTable';
import DateStatistic from '../tableComponents/dateStatistic';
import RatioBar from '../tableComponents/ratioBar';
import { clientsDataSelector } from '../../storage/reducers/clients.reducer';
import { ProjectsTable } from './projectsTable';
import moment from 'moment';

const { Text, Link } = Typography;

interface ClientsTableProps {
  title: string;
}

const ClientsTable: React.FC<ClientsTableProps> = ({
  title
}) => {
  const clients: any = useSelector(clientsDataSelector);
  const milestones: any = useSelector(milestonesActiveSelector);
  const projects: any = useSelector(projectsDataSelector);

  const [clientsToShow, setClientsToShow] =
    React.useState<any>([]);

  const [summaryData, setSummaryData] = React.useState<any>({})

  useEffect(() => {
    if (clients) {
        let totalET = 0
        let totalEP = 0
        let totalDirtySpentSum = 0;
        let totalSpentHoursSum = 0;
        let totalSpentSum = 0;
        let total_min_date = Infinity;
        let total_max_date = 0
        let totalLTV = 0
        let totalClientsProjectsWithLTV = 0
        const preparedClients = _.map( clients, (cl) => {
            let ltv = 0
            const clientProjects = _.filter(projects, (p) => p.client?.id === cl.id)
            let projectsMilestonesIDs: string[] = []
            
            _.forEach(clientProjects, cp => {
                ltv += (cp.LTV > 0 ? cp.LTV : 0)
                cp.LTV > 0 && totalClientsProjectsWithLTV++
                projectsMilestonesIDs = projectsMilestonesIDs.concat(cp.milestones)
            });
            const projectMilestones = milestones.filter(ms => projectsMilestonesIDs.includes(ms.id));
            let sumET = 0;
            let dirtySpentSum = 0;
            let spentHoursSum = 0;
            let spentSum = 0;
            let sumEP = 0
            let min_date = Infinity;
            let max_date = 0

            projectMilestones.forEach(ms => {
                sumET += parseFloat(ms.ET) || 0;
                sumEP += parseFloat(ms.EP) || 0;
                dirtySpentSum += parseFloat(ms.spent?.dirty) || 0;
                spentHoursSum += parseFloat(ms.spent?.hours) || 0;
                spentSum += parseFloat(ms.spent?.price) || 0;
                const tempMinDate = Number(ms.start_date)
                const tempMaxDate = Number(ms.due_date)
                if (min_date > tempMinDate) {
                    min_date = tempMinDate
                }
                if (max_date < tempMaxDate) {
                    max_date = tempMaxDate
                }
            });
            totalET += sumET
            totalEP += sumEP
            totalDirtySpentSum += dirtySpentSum
            totalSpentHoursSum += spentHoursSum
            totalSpentSum += spentSum
            total_min_date = Math.min(total_min_date, min_date)
            total_max_date = Math.max(total_max_date, max_date)
            totalLTV += ltv

            return { ... cl, 
                milestonesFields: {
                    ET: sumET,
                    EP: sumEP,
                    spent: {
                        dirty: dirtySpentSum,
                        hours: spentHoursSum,
                        price: spentSum
                    }
                },
                LTV: ltv,
                start_date: min_date,
                due_date: max_date
            }
        })
        setSummaryData( {
          totalET,
          totalEP,
          totalDirtySpentSum,
          totalSpentHoursSum,
          totalSpentSum,
          totalLTV,
          total_min_date,
          total_max_date,
          totalClientsProjectsWithLTV
        })
        setClientsToShow(preparedClients)
    }
  }, [clients]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      render: (name, record) => {
        return (
          <Space size={-4} direction='vertical'>
            <Link
              target='_blank'
              strong
              href={`https://app.clickup.com/2479620/t/${record.id}`}
            >
              <Text type='secondary'>{name}</Text>
            </Link>
          </Space>
        );
      },
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Timeline',
      dataIndex: 'time',
      key: 'time',
      width: '180px',
      render: (time, record) => <DateStatistic record={record} />,
    },
    {
      title: 'Ratio',
      dataIndex: 'ratio',
      key: 'ratio',
      width: '180px',
      render: (et, record) => 
        <RatioBar estimate={record.milestonesFields.ET} spent={record.milestonesFields.spent.dirty} />,
    },
    {
      title: 'Clean Hrs',
      dataIndex: 'clean',
      key: 'clean',
      width: '100px',
      render: (et, record) => Math.round(record.milestonesFields.spent.hours)
    },
    {
      title: 'COM',
      dataIndex: 'com',
      key: 'com',
      width: '100px',
      render: (et, record) => 
        record.milestonesFields.spent.dirty ?
            `${Math.round((record.milestonesFields.ET / record.milestonesFields.spent.dirty)*100)}%` 
            : ''
    },
    {
      title: 'Spent USD',
      dataIndex: 'spentUsd',
      key: 'spentUsd',
      align: 'right',
      width: '100px',
      render: (et, record) => {record.milestonesFields.spent.price ? 
        <Text style={{ color: '#0231E8' }}>
          {'$ ' + Intl.NumberFormat().format(Math.round(record.milestonesFields.spent.price))}
        </Text> : ''}
    },
    {
      title: 'E. USD',
      dataIndex: 'EP',
      key: 'ep',
      align: 'right',
      render: (ep, record) =><Text style={{ color: '#0231E8' }}>
          {
          record.milestonesFields.EP ?
            '$ ' + Intl.NumberFormat().format(Math.round(record.milestonesFields.EP))
            : null
          }
        </Text>,
    },
    {
      title: 'LTV (Months)',
      dataIndex: 'LTV',
      key: 'ltv',
      align: 'right',
      render: (ltv, record) => {
        const value = (ltv?.min_date > 0 || ltv?.max_date > 0) ? 
          moment(ltv.max_date).diff(moment(ltv.min_date), 'months', true).toFixed(1) :
          ((ltv || 0) / 1000 / 60 / 60 / 24 / 30).toFixed(1)
        return (<Text style={{ color: '#0231E8' }}>
          {(value as unknown as number) > 0 ? value : '-'}
        </Text>)
      }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: '140px',
      render: (status, mile) => {
        return (
          <Tag color={status.color} key={status}>
            {status.status?.toUpperCase()}
          </Tag>
        );
      },
    },
    // @ts-ignore TODO: fix this
  ].filter(item => !item.hidden);

  return (
    <>
      <Card size='small' title={title}>
        <Table
          size='small'
          pagination={false}
          columns={columns as any}
          scroll={{ y: 350 }}
          expandable={{
            expandedRowRender: (record) => 
            <ProjectsTable title="" clientID={record.id}/>
            // rowExpandable: (record) => record.milestones.length,
          }}
          dataSource={clientsToShow}
          rowKey='id'
          // scroll={{ y: 240, x: 800 }}
          summary={(pageData) => {
            if (!summaryData) return;
            const totalET = summaryData.totalET;
            const totalEP = summaryData.totalEP;
            const totalSpentDirty = summaryData.totalDirtySpentSum;
            const totalSpentClean = summaryData.totalSpentHoursSum;
            const totalSpentPrice = summaryData.totalSpentSum;
            const totalLTV = summaryData.totalLTV;
            const totalClientsProjectsWithLTV = summaryData.totalClientsProjectsWithLTV || 0
            
            const averageLTV = ((totalClientsProjectsWithLTV && (totalLTV / totalClientsProjectsWithLTV) || 0)
              / 1000 / 60 / 60 / 24 / 30).toFixed(1)

            const gpmUSD = totalEP - totalSpentPrice;
            const gpmPercent = totalEP ? Math.round((gpmUSD / totalEP) * 100) : 0;

            const COM = totalET / totalSpentDirty;

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    Summary <Text strong>({pageData.length})</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <Text strong>
                      {Intl.NumberFormat().format(Math.round(totalSpentDirty))+' '} 
                      | {Intl.NumberFormat().format(Math.round(totalET))}
                    </Text>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={5}>
                    <Text strong>
                      {Math.round(totalSpentClean)}
                    </Text>
                  </Table.Summary.Cell>
                    <Table.Summary.Cell index={6}>
                      {COM && (
                        <Text strong>
                          {Math.round(COM * 100)}% 
                        </Text>
                      )}
                    </Table.Summary.Cell>
                  <>
                    <Table.Summary.Cell index={7} align='right'>
                      <Text strong style={{ color: '#0231E8' }}>
                      { `$ ${String(Math.round(totalSpentPrice)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align='right'>
                      <Text strong style={{ color: '#0231E8' }}>
                        { `$ ${String(Math.round(totalEP)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                      </Text>
                    </Table.Summary.Cell>
                    </>

                  <Table.Summary.Cell index={8} align='right'>
                    <Text strong style={{ color: '#0231E8' }}>
                      {Math.round(totalLTV  / 1000 / 60 / 60 / 24 / 30)}
                    </Text>
                    <br />
                    <Text strong style={{ color: '#0231E8' }}>
                      AVG: {averageLTV}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9}>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Card>
    </>
  );
};

export { ClientsTable };
