import React from "react";
import { Layout, Row, Col, Button } from "antd";
import { Outlet } from "react-router-dom";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import styles from "./layout.module.scss";
import { MenuComp } from "../layout/menu";
import Welcome from "../../components/welcome";
import Reload from "../../components/reload";
import ReturnSU from "../members/returnSU";

const { Header, Content, Sider } = Layout;

interface IMainPageProps {}

interface IMenu {
  collapsed: boolean;
}

const LayoutPage: React.FC<IMainPageProps> = () => {
  const [menu, setMenu] = React.useState<IMenu>({ collapsed: true });

  const toggleMenu = () => setMenu({ collapsed: !menu.collapsed });

  return (
    <div className={styles.root}>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider trigger={null} collapsible collapsed={menu.collapsed}>
          <div className={styles.logo} />
          <MenuComp />
        </Sider>
        <Layout className="site-layout">
          <Header className={styles.bg} style={{ padding: 0 }}>
            <Row justify="space-between">
              <Col span={2}>
                <Row justify="center" align="middle">
                  <Col>
                    <Button
                      icon={
                        menu.collapsed ? (
                          <MenuUnfoldOutlined />
                        ) : (
                          <MenuFoldOutlined />
                        )
                      }
                      onClick={toggleMenu}
                    ></Button>
                  </Col>
                </Row>
              </Col>
              <Col span={16}>
                <Welcome></Welcome>
              </Col>
              <Col span={2}>
                <Row justify="center" align="middle">
                  <Col>
                    <ReturnSU></ReturnSU>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row justify="center" align="middle">
                  <Col>
                    <Reload></Reload>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Header>

          <Content
            className={styles.bg}
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export { LayoutPage };
