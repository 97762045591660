import React, { useState, useEffect } from "react";
import { Select, Row, Col, Button, Typography } from "antd";
import moment from "moment";
import "antd/dist/antd.css";

const { Option } = Select;
const { Text } = Typography;

interface IDateDropdownProps {
  onChange: (dates: moment.Moment[], periodLength: number) => void;
}

// Function to generate month options
const generateMonthOptions = () => {
  const months: {
    label: string;
    value: string;
    end: string;
  }[] = [];
  const currentYear = moment().year();

  for (let i = 0; i <= 11; i++) {
    // Iterate through all months
    const monthName = moment().month(i).format("MMM");
    const year = currentYear;

    // Adding two options for each month
    months.push({
      label: `${monthName} 1`,
      value: `${year}-${i + 1}-1`,
      end: `${year}-${i + 1}-15`,
    });
    months.push({
      label: `${monthName} 2`,
      value: `${year}-${i + 1}-15`,
      end: i === 11 ? `${year + 1}-1-1` : `${year}-${i + 2}-1`,
    });
  }

  return months;
};

const DateDropdowns: React.FC<IDateDropdownProps> = ({ onChange }) => {
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);
  const monthOptions = generateMonthOptions();
  const [comparisonPeriod, setComparisonPeriod] = useState("");

  const handleSaveSnapshot = () => {
    alert("Snapshot saved");
  };

  useEffect(() => {
    // Set the default values to the current period
    const currentMonth = moment().month() + 1;
    const half = moment().date() > 15 ? 2 : 1;

    // const defaultValue2 = `${moment().year()}-${half > 1 ? currentMonth + 1 : currentMonth}-${
    //   half > 1 ? 1 : 15
    // }`;
    // let defaultValue1 = defaultValue2;
    // if (half === 2) {
    //   defaultValue1 = `${moment().year()}-${currentMonth}-15`;
    // } else if (currentMonth !== 1) {
    //   defaultValue1 = `${moment().year()}-${currentMonth - 1}-15`;
    // }
    let defaultValue1 = `${moment().year()}-${currentMonth}-1`;
    let defaultValue2 = `${moment().year()}-${currentMonth}-15`;
    if (half === 2) {
      defaultValue1 = `${moment().year()}-${currentMonth}-15`;
      defaultValue2 = `${moment().year()}-${currentMonth + 1}-1`;
    }
    setFromDate(defaultValue1);
    setToDate(defaultValue2);
  }, []);

  useEffect(() => {
    if (fromDate && toDate) {
      const fromMoment = moment(fromDate, "YYYY-MM-DD");
      const toMoment = moment(toDate, "YYYY-MM-DD");
      const periodLength = toMoment.diff(fromMoment, "days");

      const previousPeriodStart = moment(fromDate, "YYYY-MM-DD").subtract(
        periodLength,
        "days"
      );
      const previousPeriodEnd = moment(toDate, "YYYY-MM-DD").subtract(
        periodLength,
        "days"
      );

      onChange(
        [fromMoment, toMoment, previousPeriodStart, previousPeriodEnd],
        periodLength
      );

      setComparisonPeriod(
        `${previousPeriodStart.format("MMM D")} - ${previousPeriodEnd.format(
          "MMM D"
        )}`
      );
    }
  }, [fromDate, toDate]);

  const handleFromChange = (value) => {
    setFromDate(value);
    if (
      toDate &&
      moment(toDate, "YYYY-MM-DD").isBefore(moment(value, "YYYY-MM-DD"))
    ) {
      setToDate(null);
    }
  };

  const handleToChange = (value) => {
    setToDate(value);
  };

  const isOptionDisabled = (optionValue, comparedValue) => {
    if (!comparedValue) return false;
    return moment(optionValue, "YYYY-MM-DD").isAfter(
      moment(comparedValue, "YYYY-MM-DD")
    );
  };

  return (
    <div>
      <div style={{ textAlign: "right", marginBottom: 20 }}>
        <Button type="primary" onClick={handleSaveSnapshot}>
          Save Snapshot
        </Button>
      </div>
      <Row gutter={16}>
        <Col span={12}>
          <Select
            placeholder="From"
            onChange={handleFromChange}
            value={fromDate}
            style={{ width: "100%" }}
          >
            {monthOptions.map((option) => (
              <Option
                key={option.value}
                value={option.value}
                disabled={toDate && isOptionDisabled(option.value, toDate)}
              >
                {option.label}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={12}>
          <Select
            placeholder="To"
            onChange={handleToChange}
            value={toDate}
            style={{ width: "100%" }}
          >
            {monthOptions.map((option) => (
              <Option
                key={option.end}
                value={option.end}
                disabled={fromDate && isOptionDisabled(fromDate, option.value)}
              >
                {option.label}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      {comparisonPeriod && (
        <Text style={{ marginTop: 20, display: "block" }}>
          Comparing vs {comparisonPeriod}
        </Text>
      )}
    </div>
  );
};

export default DateDropdowns;
