import React from 'react';
import moment from 'moment';
import { Col, Row, Progress, Statistic } from 'antd';
import { getHoursInPeriod } from '../../helpers/timeLogs';

interface TimersData {
  title: string;
  duration: number;
}
interface TimersProps {
  today: TimersData | null;
  yesterday: TimersData | null;
  thisPeriod: TimersData | null;
  lastPeriod: TimersData | null;
  thisWeek: TimersData | null;
}

const Timers: React.FC<TimersProps> = (props: TimersProps) => {
  let countProps = 0;
  const { today, yesterday, thisPeriod, lastPeriod, thisWeek } = props;
  for (const prop in props) {
    if (props[prop]) {
      countProps++;
    }
  }

  const hoursPeriod = getHoursInPeriod();

  const spanWidth = 24 / countProps < 8 ? 12 : 8;

  const getCountDown = (data: TimersData, hoursPeriod) => {
    return (
      <Col span={spanWidth} key={data.title}>
        <Row align='middle'>
          <Progress
            type='circle'
            percent={Math.round(
              (data.duration * 100) / (hoursPeriod * 3600000)
            )}
            width={50}
          />
          <Statistic
            title={data.title}
            value={moment.utc(data.duration).format('HH:mm')}
            valueStyle={{ fontSize: '0.8rem' }}
            style={{ marginLeft: '1rem' }}
          />
        </Row>
      </Col>
    );
  };

  return (
    <div>
      <Row>
        {today ? getCountDown(today, hoursPeriod.today) : null}
        {yesterday ? getCountDown(yesterday, hoursPeriod.yesterday) : null}
        {thisWeek ? getCountDown(thisWeek, hoursPeriod.thisWeek) : null}
        {thisPeriod ? getCountDown(thisPeriod, hoursPeriod.thisPeriod) : null}
        {lastPeriod ? getCountDown(lastPeriod, hoursPeriod.lastPeriod) : null}
      </Row>
    </div>
  );
};

export default Timers;
