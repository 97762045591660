import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

export interface hrsData {}

export interface hrsStateData {
  data?: hrsData[];
}

const initialState: hrsStateData = {
  data: undefined,
};

export const hrsSlice = createSlice({
  name: 'hrs',
  initialState: initialState,
  reducers: {
    set: (state, action: PayloadAction<hrsData[]>) => {
      state.data = action.payload;
    },
  },
});

export const $hrs_actions = hrsSlice.actions;

export const hrsSelector = (state: RootState): hrsStateData => state.hrs;

export const tasksDataSelector = createSelector(
    hrsSelector,
  (state) => state.data
);

export default hrsSlice.reducer;
