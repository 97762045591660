import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Avatar, Typography, Space } from 'antd';
const { Title } = Typography;
import { userDataSelector } from '../storage/reducers/user.reducer';
import moment from 'moment';
interface User {}

const Welcome: React.FC<any> = () => {
  const user: User | any = useSelector(userDataSelector);
  console.log('user', user?.username);

  let userAvatar = '';
  if (user?.username?.trim()?.split(' ')?.length > 1) {
    userAvatar = `${user.username
      .trim()
      .split(' ')[0]
      .substring(0, 1)}${user.username.trim().split(' ')[1].substring(0, 1)}`;
  } else if (user?.username?.trim()?.split(' ')?.length === 1) {
    userAvatar = `${user.username.trim().split(' ')[0].substring(0, 1)}`;
  }

  return (
    <Row justify='start' align='middle'>
      <Col>
        {user?.clickup?.profilePicture ? (
          <Avatar src={user.clickup.profilePicture} size={50} />
        ) : (
          <Avatar size={50}>{userAvatar}</Avatar>
        )}
      </Col>
      <Col>
        <Space
          direction='vertical'
          size={0}
          style={{ display: 'flex', marginLeft: '0.5rem' }}
        >
          <Title level={4} style={{ margin: 0 }}>
            {user ? `Welcome, ${user.username}` : ''}
          </Title>
          <Title level={5} type='secondary' style={{ margin: 0 }}>
            Cheers, and happy activities -{' '}
            {moment(new Date()).format('MMM DD, YYYY')}
          </Title>
        </Space>
      </Col>
    </Row>
  );
};

export default Welcome;
