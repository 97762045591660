import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { tasksDataSelector } from "../../storage/reducers/tasks.reducer";
import { milestonesDataSelector } from "../../storage/reducers/milestones.reducer";
import { projectsDataSelector } from "../../storage/reducers/projects.reducer";
import { Divider, Tabs } from "antd";
import Timers from "../../components/common/timers";
import TasksTable from "../../components/common/tasksTable";
import { MilestonesTable } from "../../components/common/milestones-table";
import { getTimeLogs } from "../../helpers/timeLogs";
import { userDataSelector } from "../../storage/reducers/user.reducer";
import { isDev } from "../../helpers";
import _ from "underscore";
const { TabPane } = Tabs;
interface Milestone {
  [key: string]: string;
}
interface Project {
  [key: string]: string;
}
interface Task {
  mileStoneName: string;
  mileStoneId: string;
  listRef: string;
  projectName: string;
  projectId: string;
  status: {
    status: string;
  };
}
interface Tasks {
  [index: number]: Task;
}
interface Milestones {
  [index: number]: Milestone;
}
interface Projects {
  [index: number]: Project;
}
interface CommonDashboardProps {
  userPosition: string;
}

interface User {}

interface TimeLog {
  duration: number;
  title: string;
}

interface TimeLogs {
  today: TimeLog | null;
  yesterday: TimeLog | null;
  thisWeek: TimeLog | null;
  thisPeriod: TimeLog | null;
  lastPeriod: TimeLog | null;
}

// dashboard for dev and qa users as they similar enough
const CommonDashboard: React.FC<CommonDashboardProps> = ({ userPosition }) => {
  const tasks: Tasks | any = useSelector(tasksDataSelector);
  const milestones: Milestones | any = useSelector(milestonesDataSelector);
  const projects: any = useSelector(projectsDataSelector);
  const user: User | any = useSelector(userDataSelector);
  const [milestonesToShow, setMilestonesToShow] = useState<Milestones | any>(
    milestones
  );
  const [activeMilestones, setActiveMilestones] = useState<Milestones | any>(
    milestones
  );
  const [closedMilestones, setClosedMilestones] = useState<Milestones | any>(
    milestones
  );
  const [newTasks, setNewTasks] = useState<Tasks | any>([]);
  const [activeTasks, setActiveTasks] = useState<Tasks | any>([]);
  const [closedTasks, setClosedTasks] = useState<Tasks | any>([]);
  const [timersData, setTimersData] = useState<TimeLogs | null>(null);

  useEffect(() => {
    if (milestones) {
      const newMilestones = [...Object.values(milestones)].map(
        (el: Milestone | any) => {
          let projectName = "";
          projects &&
            Object.keys(projects).forEach((proj) => {
              projectName = projects[proj]?.milestones?.includes(el.id)
                ? projects[proj]?.name
                : "";
            });
          const newTasks: Task[] = [];

          el.tasks.forEach((task) => {
            if (tasks && tasks[task]) {
              newTasks.push(tasks[task]);
            }
          });

          return { ...el, projectName, tasks: newTasks };
        }
      );
      const activeMilestones: any[] = [];
      const closedMilestones: any[] = [];

      _.map(newMilestones, (mile) => {
        if (_.contains(mile.flowTo, "closed") || mile.flowTo === "closed") {
          closedMilestones.push(mile);
        } else {
          activeMilestones.push(mile);
        }
      });

      setMilestonesToShow(newMilestones);
      setActiveMilestones(activeMilestones);
      setClosedMilestones(closedMilestones);
    }
  }, [milestones, projects, tasks]);

  useEffect(() => {
    if (tasks && milestones) {
      const _activeTasks: Task[] = [];
      const _closedTasks: Task[] = [];
      const newTasks = Object.keys(tasks).map((id) => {
        const newTask: Task = { ...tasks[id] };

        const mile: Milestone | any = [...Object.values(milestones)].find(
          (ml: any) => ml.tasks.includes(id)
        );
        if (mile) {
          newTask.mileStoneName = mile.name;
          newTask.mileStoneId = mile.id;
          newTask.listRef = mile.listRef;

          const proj: Project | any = [...Object.values(projects)].find(
            (pr: any) => pr.milestones.includes(mile.id)
          );

          if (proj) {
            newTask.projectName = proj.name;
            newTask.projectId = proj.id;
          }
        }

        if (newTask.status.status === "done") {
          _closedTasks.push(newTask);
        } else {
          _activeTasks.push(newTask);
        }

        return newTask;
      });

      setNewTasks(newTasks);
      setActiveTasks(_activeTasks);
      setClosedTasks(_closedTasks);
    }
  }, [tasks, milestones]);

  useEffect(() => {
    if (user?.timelogs) {
      setTimersData(
        getTimeLogs(user.timelogs, ["today", "yesterday", "thisWeek"])
      );
    }
  }, [user?.timelogs]);

  return (
    <div>
      {isDev(userPosition) && timersData ? (
        <>
          <Timers {...timersData}></Timers>
          <Divider />
        </>
      ) : null}
      <Tabs type="card" defaultActiveKey="1">
        <TabPane tab="My Tasks" key="1">
          <Tabs type="card" defaultActiveKey="1">
            <TabPane tab="Active" key="1">
              <TasksTable
                tasks={activeTasks}
                userPosition={userPosition}
              ></TasksTable>
            </TabPane>
            <TabPane tab="Closed" key="2">
              <TasksTable
                tasks={closedTasks}
                userPosition={userPosition}
              ></TasksTable>
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane tab="My Projects" key="2">
          <Tabs type="card" defaultActiveKey="1">
            <TabPane tab="Active" key="1">
              <MilestonesTable
                milestones={activeMilestones}
                userPosition={userPosition}
                title={""}
              ></MilestonesTable>
            </TabPane>
            <TabPane tab="Closed" key="2">
              <MilestonesTable
                milestones={closedMilestones}
                userPosition={userPosition}
                title={""}
              ></MilestonesTable>
            </TabPane>
          </Tabs>
        </TabPane>
      </Tabs>
    </div>
  );
};

export { CommonDashboard };
