import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'underscore';
import { RootState } from '..';

export interface MilestonesData {
  one: string
}
export interface MilestonesStateData {
  data?: MilestonesData;
}

const initialState: MilestonesStateData = {
  data: undefined,
};

export const MilestonesSlice = createSlice({
  name: 'milestone',
  initialState: initialState,
  reducers: {
    set: (state, action: PayloadAction<MilestonesData>) => {
      state.data = action.payload;
    },
  },
});

export const $milestones_actions = MilestonesSlice.actions;

export const milestonesSelector = (state: RootState): MilestonesStateData => state.milestones;

export const milestonesDataSelector = createSelector(
  milestonesSelector,
  (state) => state.data
);

export const milestonesArraySelector = createSelector(
  milestonesSelector,
  (state) => {
  return _.map(state.data, (item) => item)
  }
);

export const milestonesActiveSelector = createSelector(
  milestonesSelector,
  (state) => {
    // @ts-ignore btw, this is a bug in typescript
    return _.filter(state.data, (item) => !_.contains(item.flowTo, 'closed'));
  }
);

export default MilestonesSlice.reducer;
