import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import {
  $user_actions,
  userDataSelector
} from '../../storage/reducers/user.reducer';
import { useMapDispatch } from '../../hooks/utils.hooks';

interface ProfileProps {}

const Profile: React.FC<ProfileProps> = () => {
  const user = useSelector(userDataSelector);

  const handleLogout = useMapDispatch($user_actions.logout);
  console.log(user);

  const togleLogout = () => {
    handleLogout(undefined);
    localStorage.removeItem('jwt');
  };

  return (
    <div>
      <Row justify='space-between'>
        <Col span={22}>
          <div> Profile: {user?.username} </div>
          <div> Position: {user?.position} </div>
        </Col>
        <Col span={2}>        
          <Row justify="end">           
              <Button icon={<LogoutOutlined />} onClick={togleLogout}></Button>       
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export { Profile };
