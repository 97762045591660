import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import Client from '../../types/Client';

export interface ClientsStateData {
    data?: Client[];
}

const initialState: ClientsStateData = {
  data: undefined,
};

export const ClientsSlice = createSlice({
  name: 'clients',
  initialState: initialState,
  reducers: {
    set: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    },
  },
});

export const $clients_actions = ClientsSlice.actions;

export const clientsSelector = (state: RootState): ClientsStateData => state.clients;

export const clientsDataSelector = createSelector(
  clientsSelector,
  (state) => state.data
);

export default ClientsSlice.reducer;
