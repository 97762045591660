const LOCAL_BACK = 1;
let AH;
if (process.env.NODE_ENV == "development") {
  AH = LOCAL_BACK ? "http://localhost:1331" : "https://uni-internal.co";
} else {
  AH = window.location.href.includes("port.")
    ? "https://uni-internal.co"
    : "https://dev.uni-internal.co";
}

export const API_HOST = AH;
// export const API_HOST = 'https://uni-internal.co'
console.log(API_HOST);

export const USER_CONNECT = "/api/auth/local";
export const CURRENT_USER = "/api/users/me";

export const PROJECTS = "/api/project";
export const MILESTONES = "/api/project/milestones";
export const ASSOCIATED_LIST = "/api/project/associated-lists";

export const TASKS = "/api/task";
export const RELOAD = "/api/tests/sync";

export const MEMBERS = "/api/members";

export const QA_PROJECTS = "/api/qa/projects";
export const QA_MILESTONES = "/api/qa/milestones";
export const QA_TASKS = "/api/qa/tasks";
export const HR = "/api/hr";
// export const LEADS = "/api/sales";
export const CLIENTS = "/api/project/clients";
export const SYNCDATA = "/api/tests/syncdata";
