import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ROUTES } from "../constants/router.constants";
import { MainPage } from "../pages/main.page";
import { AuthPage } from "../pages/auth.page";
import { useSelector } from "react-redux";
import { userDataSelector } from "../storage/reducers/user.reducer";
import { Dashboard } from "../pages/dashboard";
import { Profile } from "../pages/profile";
import { Members } from "../pages/members";
// import { SalesDashboard } from '../pages/salesDashboard'
import { Reports } from "../pages/reports";
import GoogleAuthCallback from "../pages/google/google.auth.callback";

const PagesRoutes: React.FC = () => {
  const user = useSelector(userDataSelector);

  return (
    <Router>
      <Routes>
        <Route
          element={
            user ? (
              <MainPage />
            ) : (
              <Navigate replace to={ROUTES.AUTH + window.location.search} />
            )
          }
        >
          <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
          <Route path={ROUTES.PROFILE} element={<Profile />} />
          <Route path={ROUTES.MEMBERS} element={<Members />} />
          {/* <Route path={ROUTES.SALES} element={<SalesDashboard/>}/> */}
          <Route
            path={ROUTES.REPORTS}
            element={
              ["SU", "Delivery Manager"].includes(user?.position || "") ? (
                <Reports forSU={user?.position === "SU"} />
              ) : null
            }
          />
          <Route
            path="*"
            element={<Navigate to={ROUTES.DASHBOARD} replace />}
          />
        </Route>
        <Route path={ROUTES.AUTH} element={<AuthPage />} />
      </Routes>
    </Router>
  );
};

export { PagesRoutes };
