import React, { useEffect } from 'react';
import { Card, Table } from 'antd';
import _ from 'underscore';
import expandedTasksTable from './expandedTasksTable';
import getColumns from './getColumns';
import {
  MILESTONE_COM,
  MILESTONE_NAME,
  MILESTONE_QA_TASKS,
  MILESTONE_RATIO,
  MILESTONE_STATUS,
  MILESTONE_TIMELINE,
} from '../../constants/columnConstants';
import Task from '../../types/Task';
import { isDev, isPM } from '../../helpers';

interface Milestone {
  id: string;
  tasks: any | Tasks;
  [key: string]: string;
  custom_fields: any;
}
interface Milestones {
  [index: number]: Milestone;
}
interface Tasks {
  [index: number]: Task;
}
interface MilestonesTableProps {
  milestones: any | Milestones;
  userPosition: string;
  title: string | null;
}

interface Column {}

const getUserColumns = (userPosition: string): Column[] => {
  if (isDev(userPosition)) {
    return getColumns([MILESTONE_NAME, MILESTONE_TIMELINE, MILESTONE_STATUS]);
  } else if (userPosition === 'QA') {
    return getColumns([
      MILESTONE_NAME,
      MILESTONE_TIMELINE,
      MILESTONE_STATUS,
      MILESTONE_QA_TASKS,
    ]);
  } else if (isPM(userPosition)) {
    return getColumns([
      MILESTONE_NAME,
      MILESTONE_TIMELINE,
      MILESTONE_RATIO,
      MILESTONE_COM,
      MILESTONE_STATUS,
    ]);
  } else {
    return [];
  }
};

const MilestonesTable: React.FC<MilestonesTableProps> = (
  props: MilestonesTableProps
) => {
  const { milestones, userPosition, title } = props;

  return (
    <Card size='small' title={title ? title : ''}>
      <Table
        size='small'
        pagination={false}
        columns={
          title === 'Closed Milestones'
            ? [...getUserColumns(userPosition)].slice(0, -1)
            : getUserColumns(userPosition)
        }
        expandable={{
          expandedRowRender: (record: Milestone) =>
            expandedTasksTable(record, userPosition),
        }}
        dataSource={milestones}
        rowKey='id'
      />
    </Card>
  );
};

export { MilestonesTable };
