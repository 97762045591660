import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { tasksDataSelector } from '../../storage/reducers/tasks.reducer';
import { milestonesDataSelector } from '../../storage/reducers/milestones.reducer';
import { projectsDataSelector } from '../../storage/reducers/projects.reducer';
import { Tabs } from 'antd';
import { MilestonesTable } from '../../components/common/milestones-table';
import Task from '../../types/Task';
import Milestone from '../../types/Milestone';
import { ProjectsTable } from '../../components/superuser/projectsTable';
import { MilestonesTableExtended } from '../../components/superuser/milestonesTableExtended';
const { TabPane } = Tabs;

interface PMDashboardProps {
}

// interface Milestone {
//   [key: string]: string;
// }
// interface Task {
//   [key: string]: string;
// }
interface Tasks {
  [index: number]: Task;
}
interface Milestones {
  [index: number]: Milestone;
}

const PMDashboard: React.FC<PMDashboardProps> = () => {
  const tasks: Tasks | any = useSelector(tasksDataSelector);
  const milestones: Milestones | any = useSelector(milestonesDataSelector);
  const projects: any = useSelector(projectsDataSelector);

  const [milestonesActive, setMilestonesActive] = useState<Milestones | any>(
    milestones
  );
  const [milestonesClosed, setMilestonesClosed] = useState<Milestones | any>(
    milestones
  );

  useEffect(() => {
    if (milestones && tasks) {
      const newMilestones = [...Object.values(milestones)].map(
        (el: Milestone | any) => {
          let projectName = '';
          projects &&
            Object.keys(projects).forEach((proj) => {
              projectName = projects[proj]?.milestones?.includes(el.id)
                ? projects[proj]?.name
                : '';
            });
          const newTasks: Task[] = [];

          el.tasks.forEach((task) => {
            if (tasks && tasks[task]) {
              newTasks.push(tasks[task]);
            }
          });

          return { ...el, projectName, tasks: newTasks };
        }
      );

      setMilestonesActive([
        ...newMilestones.filter((mile) => mile?.flowTo?.[0] === 'active'),
      ]);
      setMilestonesClosed([
        ...newMilestones.filter(
          (mile) =>
            mile?.flowTo?.[0] &&
            (mile.flowTo[0] === 'closed' || mile.flowTo[0] === 'to_close')
        ),
      ]);
    }
  }, [milestones, projects, tasks]);

  return (
    <Tabs type="card" defaultActiveKey='1'>
        <TabPane tab="Active Milestones" key="2">
          <MilestonesTableExtended isPM title="Active Milestones" type="active" />
        </TabPane>
        <TabPane tab="Closed Milestones" key="3">
          <MilestonesTableExtended isPM title="Closed Milestones" type="closed" />
        </TabPane>
        <TabPane tab="Projects" key="7">
          <ProjectsTable title="Projects Table" isPM/>
        </TabPane>
       </Tabs>   
  );
};

export { PMDashboard };
