import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

export interface InternalStateData {
  lastSynced?: number;
}

const initialState: InternalStateData = {
  lastSynced: undefined,
};

export const InternalSlice = createSlice({
  name: "internal",
  initialState: initialState,
  reducers: {
    set: (state, action: PayloadAction<any>) => {
      state.lastSynced = action.payload.lastSyncDate;
    },
  },
});

export const $internal_actions = InternalSlice.actions;

export const internalSelector = (state: RootState): InternalStateData =>
  state.internal;

export const internalDataSelector = createSelector(
  internalSelector,
  (state) => state
);

export default InternalSlice.reducer;
