import React, { useState, useEffect } from "react";
import {
  DatePicker,
  Tooltip,
  Tabs,
  Card,
  Row,
  Col,
  Statistic,
  Typography,
  Popover,
  Button,
  List,
} from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import DateDropdowns from "./monthPicker";

import moment from "moment";
import { useSelector } from "react-redux";
import { projectsDataSelector } from "../../storage/reducers/projects.reducer";
import { milestonesDataSelector } from "../../storage/reducers/milestones.reducer";
import _ from "underscore";
import { membersDataSelector } from "../../storage/reducers/members.reducer";
import { ProjectsTable } from "../../components/superuser/projectsTable";
import { Members } from "../members";
import { tasksDataSelector } from "../../storage/reducers/tasks.reducer";
import { MilestonesTableExtended } from "../../components/superuser/milestonesTableExtended";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
interface ReportsProps {
  forSU?: boolean;
}
const { Title, Text } = Typography;

const getReportMilestoneColor = (item: {
  billable: boolean;
  overdue: boolean;
  [key: string]: any;
}) => {
  let color = !item.billable ? "blue" : "black";
  if (item.overdue) {
    color = "red";
  }
  return color;
};

const projects_dummy = [
  {
    name: "Project Alpha",
    budget: 10000,
    expenses: 5000,
    spi: 10,
    com: 10,
    ratio: 10,
  },
  {
    name: "Project Beta",
    budget: 15000,
    expenses: 5000,
    spi: 10,
    com: 10,
    ratio: 10,
  },
  {
    name: "Project Gamma",
    budget: 20000,
    expenses: 5000,
    spi: 10,
    com: 10,
    ratio: 10,
  },
];

const team = [
  { name: "Igor Marga", hours: 100, tasks: 10, salary: 1000 },
  { name: "Igor Marga", hours: 100, tasks: 10, salary: 1000 },
  { name: "Igor Marga", hours: 100, tasks: 10, salary: 1000 },
];

// StatCard Component
const StatCard = ({
  title,
  value,
  previousValue,
  precision = 2,
  prefix = "",
  popoverContent,
}) => {
  // Calculate the difference and the percentage change
  const difference = value - previousValue;
  let percentageChange = (difference / previousValue) * 100;
  if (!isFinite(percentageChange)) {
    percentageChange = 100;
  }

  // Determine color and icon based on positive or negative change
  const isPositive = difference >= 0;
  const color = isPositive ? "green" : "red";
  const icon = isPositive ? <ArrowUpOutlined /> : <ArrowDownOutlined />;

  return (
    //
    <Popover content={popoverContent} title={title} trigger="hover">
      <Card>
        <Statistic
          title={title}
          value={Math.round(value)}
          precision={precision}
          prefix={prefix}
          suffix={
            <span style={{ color, fontSize: 15 }}>
              {isPositive ? "+" : ""}
              {Math.round(percentageChange || 0)}% ({difference >= 0 ? "+" : ""}
              {Math.round(difference || 0)})
            </span>
          }
        />
      </Card>
    </Popover>
  );
};

interface ReportProjectDataItem {
  value: number;
  previousValue: number;
  list: any;
}

export interface ReportProjectsData {
  income: ReportProjectDataItem;
  expenses: ReportProjectDataItem;
  expectedIncome: ReportProjectDataItem;
  gpm: ReportProjectDataItem;
  activeProjects: ReportProjectDataItem;
  closedProjects: ReportProjectDataItem;
  toCloseProjects?: ReportProjectDataItem;
  com: ReportProjectDataItem;
  ratio: ReportProjectDataItem;
  spi: ReportProjectDataItem;
  teamSize: ReportProjectDataItem;
  hoursTracked: ReportProjectDataItem;
  taskClosed: ReportProjectDataItem;
  filteredProjects: any;
  filteredMilestones: any;
  filteredTasks: any;
  filteredMembers: any;
  fromTo: [moment.Moment, moment.Moment];
}

const Reports: React.FC<ReportsProps> = ({ forSU }) => {
  const projects = useSelector(projectsDataSelector);
  const milestones = useSelector(milestonesDataSelector);
  const members = useSelector(membersDataSelector);
  const tasks = useSelector(tasksDataSelector);
  const [reportProjectsData, setReportProjectsData] =
    useState<ReportProjectsData | null>(null);
  const [reportDates, setReportDates] = useState<moment.Moment[] | null>(null);
  function onDateChange(dates, periodLength: number) {
    setReportDates(dates);
  }

  const calculateReportsData = (
    minDate: moment.Moment,
    maxDate: moment.Moment
  ) => {
    if (projects && milestones && tasks && members) {
      const activeProjects = {
        value: 0,
        previousValue: 0,
        list: {},
      };
      const closedProjects = {
        value: 0,
        previousValue: 0,
        list: {},
      };
      const teamSize = {
        value: 0,
        previousValue: 0,
        list: {},
      };
      const hoursTracked = {
        value: 0,
        previousValue: 0,
        list: {},
      };
      const taskClosed = {
        value: 0,
        previousValue: 0,
        list: {},
      };

      const filteredProjects = _.filter(
        JSON.parse(JSON.stringify(projects)),
        (p) => {
          // Calculate active and closed projects in Period, can be removed if no needed
          // if (p.max_date) {
          //   if (p.max_date > maxDate.valueOf() && p.min_date < minDate.valueOf()) {
          //     activeProjects.value ++
          //     activeProjects.list[p.id] = {
          //       name: p.name,
          //     }
          //   }
          //   else if (p.max_date <= maxDate.valueOf()) {
          //     closedProjects.value ++
          //     closedProjects.list[p.id] = {
          //       name: p.name,
          //     }
          //   }
          // } else {
          //   activeProjects.value ++
          //   activeProjects.list[p.id] = {
          //     name: p.name,
          //   }
          // }

          if (p.min_date && p.min_date <= maxDate.valueOf()) {
            return true;
          }
        }
      );

      const filteredMilestones: any = {};
      _.map(JSON.parse(JSON.stringify(milestones)), (mlstn) => {
        const tempMinDate = Number(mlstn.start_date || mlstn.date_created);
        const tempMaxDate = Number(mlstn.closedDate || mlstn.due_date);

        if (tempMinDate && tempMinDate <= maxDate.valueOf()) {
          if (tempMaxDate <= maxDate.valueOf()) {
            // there was a closed projects
          } else {
            activeProjects.value++;
            activeProjects.list[mlstn.id] = {
              name: mlstn.name,
              billable: mlstn.billable,
              overdue: mlstn.overdue,
            };
            mlstn.reportStatus = "active";
          }

          mlstn.spent = {
            dirty: 0,
            hours: 0,
            price: 0,
            users: {},
          };
          mlstn.overdue =
            mlstn.due_date < minDate.valueOf() && mlstn.status !== "paid";

          filteredMilestones[mlstn.id] = mlstn;
          return true;
        }
      });

      const filteredTasks: any = {};
      _.each(JSON.parse(JSON.stringify(tasks)), (t) => {
        if (filteredMilestones[t.milestoneID]) {
          if (
            t.date_done &&
            t.date_done >= minDate.valueOf() &&
            t.date_done < maxDate.valueOf()
          ) {
            const assignees = t.assignees?.clickup?.id;
            const member = members.find((el) => el.clickup_id == assignees);
            if (member) {
              if (!taskClosed.list[assignees]) {
                taskClosed.list[assignees] = {
                  id: assignees,
                  name: member.username || member.name,
                  value: 0,
                };
              }

              taskClosed.value++;
              taskClosed.list[assignees].value++;
            }
          }
          filteredTasks[t.id] = t;
        }
      });

      const filteredMembers = {};
      _.filter(JSON.parse(JSON.stringify(members)), (mmbr) => {
        if (
          mmbr.pf_data?.hired_on &&
          moment(mmbr.pf_data?.hired_on).valueOf() < maxDate.valueOf()
        ) {
          teamSize.value++;
          teamSize.list[mmbr.id] = {
            name: mmbr.username,
          };
          hoursTracked.list[mmbr.id] = {
            itemID: mmbr.id,
            name: mmbr.username,
            value: 0,
          };
          mmbr.activeMilestones = _.filter(
            mmbr.activeMilestones,
            (mId) => filteredMilestones[mId]
          );
          mmbr.activeTasks = _.filter(mmbr.tasks, (id) => filteredTasks[id]);
          mmbr.timelogs = _.filter(mmbr.timelogs, (tl) => {
            if (tl.start >= minDate.valueOf() && tl.start < maxDate.valueOf()) {
              hoursTracked.value += tl.duration;
              hoursTracked.list[mmbr.id].value += tl.duration;

              const task = filteredTasks[tl.task.id];
              if (task) {
                task.periodActive = true;
                const milestone = filteredMilestones[task.milestoneID];
                if (milestone) {
                  // {
                  //   dirty: 0,
                  //   hours: 0,
                  //   price: 0,
                  //   users: {},
                  // };
                  if (!milestone.spent.users[mmbr.id]) {
                    milestone.spent.users[mmbr.id] = {
                      hours: 0,
                      price: 0,
                      profile: mmbr,
                      name: mmbr.username,
                      position: mmbr?.position,
                    };
                  }
                  const userSpent = milestone.spent.users[mmbr.id];
                  const hours = tl.duration / 1000 / 60 / 60;
                  const price = hours * (mmbr.salary / 168 || 0);
                  userSpent.hours += hours;
                  userSpent.price += price;
                  milestone.spent.hours += hours;
                  milestone.spent.price += price;
                  milestone.spent.dirty += hours;
                }
              }

              return true;
            }
          });
          filteredMembers[mmbr.id] = mmbr;
          return true;
        }
      });

      const income = {
        value: 0,
        previousValue: 0,
        list: {},
      };
      const expectedIncome = {
        value: 0,
        previousValue: 0,
        list: {},
      };
      const gpm = {
        value: 0,
        previousValue: 0,
        list: {},
      };
      const com = {
        value: 0,
        previousValue: 0,
        list: {},
      };
      const ratio = {
        value: 0,
        previousValue: 0,
        list: {},
      };
      const spi = {
        value: 0,
        previousValue: 0,
        list: {},
      };
      _.forEach(filteredProjects, (p) => {
        p.milestones = p.milestones.filter((mID) => {
          const mlstn = filteredMilestones[mID];
          if (mlstn) {
            const tempMinDate = Number(mlstn.start_date || mlstn.date_created);
            const tempMaxDate = Number(mlstn.closedDate || mlstn.due_date);
            const billable =
              mlstn.billable === true || mlstn.billable === "true";
            if (
              tempMaxDate &&
              tempMaxDate >= minDate.valueOf() &&
              tempMinDate <= maxDate.valueOf()
            ) {
              if (mlstn.EP) {
                const mlstnGPMValue = billable
                  ? [mlstn.EP, mlstn.spent.price || 0]
                  : [0, mlstn.spent.price || 0];
                gpm.list[mlstn.id] = {
                  name: mlstn.name,
                  value: mlstnGPMValue,
                  billable,
                  overdue: mlstn.overdue,
                };
                gpm.value += mlstnGPMValue[0] - mlstnGPMValue[1];
              }
              if (parseFloat(mlstn.spent?.dirty)) {
                const milestoneCOM =
                  parseFloat(mlstn.ET) / (parseFloat(mlstn.spent?.dirty) || 0);
                com.value += milestoneCOM;
                com.list[mlstn.id] = {
                  name: mlstn.name,
                  value: milestoneCOM,
                  billable,
                  overdue: mlstn.overdue,
                };
              }

              if (parseFloat(mlstn.ET)) {
                const milestoneRatio =
                  (parseFloat(mlstn.spent?.dirty) || 0) / parseFloat(mlstn.ET);
                ratio.value += milestoneRatio;
                ratio.list[mlstn.id] = {
                  name: mlstn.name,
                  value: milestoneRatio,
                  billable,
                  overdue: mlstn.overdue,
                };
              }

              const closedTasksEstimatesSum = mlstn.closedTasksEstimatesSum;
              let currentDate = Date.now();
              if (currentDate > maxDate.valueOf()) {
                currentDate = maxDate.valueOf();
              }
              // if project already finished
              if (mlstn.due_date && currentDate > mlstn.due_date) {
                currentDate = mlstn.due_date;
              }
              const expectedClosedHours =
                (currentDate - mlstn.start_date) / 1000 / 60 / 60;
              const SPI: number | null = expectedClosedHours
                ? closedTasksEstimatesSum / expectedClosedHours
                : null;

              if (SPI) {
                spi.value += SPI;
                spi.list[mlstn.id] = {
                  name: mlstn.name,
                  value: SPI,
                  billable,
                  overdue: mlstn.overdue,
                };
              }
            }

            if (
              mlstn.closedDate &&
              mlstn.closedDate >= minDate.valueOf() &&
              mlstn.closedDate <= maxDate.valueOf()
            ) {
              const mlstnIncomeValue = billable ? mlstn.EP || 0 : 0;
              income.list[mlstn.id] = {
                name: mlstn.name,
                value: mlstnIncomeValue,
                billable,
                overdue: mlstn.overdue,
              };
              income.value += parseFloat(mlstnIncomeValue);
              closedProjects.value++;
              closedProjects.list[mlstn.id] = {
                name: mlstn.name,
                billable: mlstn.billable,
                overdue: mlstn.overdue,
              };
              mlstn.reportStatus = "closed";
            }

            if (
              mlstn.due_date &&
              ((mlstn.due_date >= minDate.valueOf() &&
                mlstn.due_date <= maxDate.valueOf()) ||
                (mlstn.due_date < minDate.valueOf() && mlstn.status !== "paid"))
            ) {
              const mlstnExpectedIncomeValue = billable ? mlstn.EP || 0 : 0;
              expectedIncome.list[mlstn.id] = {
                name: mlstn.name,
                value: mlstnExpectedIncomeValue,
                billable,
                overdue: mlstn.overdue,
              };

              expectedIncome.value += mlstnExpectedIncomeValue;
            }
            return true;
          }
        });
      });

      if (com.value) {
        com.value = com.value / Object.keys(com.list).length;
      }
      if (ratio.value) {
        ratio.value = ratio.value / Object.keys(ratio.list).length;
      }
      if (spi.value) {
        spi.value = spi.value / Object.keys(spi.list).length;
      }

      const periodMult =
        Math.floor(maxDate.diff(minDate, "months", true) * 10) / 10;
      const expenses = {
        value: 0,
        previousValue: 0,
        list: {},
      };
      _.forEach(members, (m) => {
        if (m.salary) {
          expenses.value += m.salary * periodMult;
          expenses.list[m.id] = {
            name: m.username,
            value: m.salary * periodMult,
          };
        }
      });

      return {
        income,
        expectedIncome,
        spi,
        ratio,
        gpm,
        com,
        activeProjects,
        closedProjects,
        expenses,
        filteredProjects,
        filteredMilestones,
        filteredTasks,
        filteredMembers,
        taskClosed,
        teamSize,
        hoursTracked,
        fromTo: [minDate, maxDate] as [moment.Moment, moment.Moment],
      };
    }
  };

  useEffect(() => {
    if (reportDates && projects && milestones && tasks && members) {
      // "A: " dates for check
      // const currentReportsData = calculateReportsData(moment(1695009600000), moment(1696132800000))
      // const previousReportsData = calculateReportsData(moment(1693540800000), moment(1695009600000))
      // const currentReportsData = calculateReportsData(moment(1696132800000), moment(1697342400000))
      // const previousReportsData = calculateReportsData(moment(1694750400000), moment(1696132800000))
      const currentReportsData = calculateReportsData(
        reportDates[0],
        reportDates[1]
      );
      const previousReportsData = calculateReportsData(
        reportDates[2],
        reportDates[3]
      );
      if (previousReportsData && currentReportsData) {
        currentReportsData.income.previousValue =
          previousReportsData.income.value;
        currentReportsData.com.previousValue = previousReportsData.com.value;
        currentReportsData.expectedIncome.previousValue =
          previousReportsData.expectedIncome.value;
        currentReportsData.spi.previousValue = previousReportsData.spi.value;
        currentReportsData.ratio.previousValue =
          previousReportsData.ratio.value;
        currentReportsData.gpm.previousValue = previousReportsData.gpm.value;
        currentReportsData.activeProjects.previousValue =
          previousReportsData.activeProjects.value;
        currentReportsData.closedProjects.previousValue =
          previousReportsData.closedProjects.value;
        currentReportsData.expenses.previousValue =
          previousReportsData.expenses.value;
        currentReportsData.hoursTracked.previousValue =
          previousReportsData.hoursTracked.value;
        currentReportsData.taskClosed.previousValue =
          previousReportsData.taskClosed.value;
        currentReportsData.teamSize.previousValue =
          previousReportsData.teamSize.value;

        setReportProjectsData(currentReportsData);
      }
    }
  }, [reportDates, projects, milestones, members, tasks]);

  return (
    <div>
      <DateDropdowns onChange={onDateChange} />
      {reportProjectsData ? (
        <Tabs defaultActiveKey="1" style={{ marginTop: 20 }}>
          <TabPane tab="Projects" key="1">
            {/* Content for Projects Tab */}
            {forSU ? (
              <>
                <Title level={3}>Business</Title>
                <Row gutter={16}>
                  <Col span={8}>
                    <StatCard
                      title="Income"
                      value={reportProjectsData.income.value}
                      previousValue={reportProjectsData.income.previousValue}
                      prefix="$"
                      popoverContent={
                        <List
                          size="small"
                          dataSource={
                            Object.values(
                              _.chain(reportProjectsData.income.list)
                                .sortBy((item) => item.overdue)
                                .sortBy((item) => !item.billable)._wrapped
                            ) as unknown as {
                              name: string;
                              value: number;
                              billable: boolean;
                              overdue: boolean;
                            }[]
                          }
                          renderItem={(item) => (
                            <List.Item>
                              <Text
                                style={{
                                  flex: 1,
                                  color: getReportMilestoneColor(item),
                                }}
                              >
                                {item.name}
                              </Text>
                              <Text
                                strong
                                style={{ paddingLeft: 20, color: "blue" }}
                              >{`$${Math.round(
                                item.value
                              ).toLocaleString()}`}</Text>
                            </List.Item>
                          )}
                        />
                      }
                    />
                  </Col>
                  <Col span={8}>
                    <StatCard
                      title="Expenses"
                      value={reportProjectsData.expenses.value}
                      previousValue={reportProjectsData.expenses.previousValue}
                      prefix="$"
                      popoverContent={
                        <List
                          size="small"
                          dataSource={
                            Object.values(
                              reportProjectsData.expenses.list
                            ) as unknown as {
                              name: string;
                              value: number;
                              billable: boolean;
                              overdue: boolean;
                            }[]
                          }
                          renderItem={(item) => (
                            <List.Item>
                              <Text
                                style={{
                                  flex: 1,
                                  // color: !item.billable ? "blue" : "black",
                                }}
                              >
                                {item.name}
                              </Text>
                              <Text
                                style={{ paddingLeft: 20, color: "red" }}
                                strong
                              >{`-$${Math.round(item.value)}`}</Text>
                            </List.Item>
                          )}
                        />
                      }
                    />
                  </Col>
                  <Col span={8}>
                    <StatCard
                      title="Expected Income"
                      value={reportProjectsData.expectedIncome.value}
                      previousValue={
                        reportProjectsData.expectedIncome.previousValue
                      }
                      prefix="$"
                      popoverContent={
                        <List
                          size="small"
                          dataSource={
                            Object.values(
                              _.chain(reportProjectsData.expectedIncome.list)
                                .sortBy((item) => item.overdue)
                                .sortBy((item) => !item.billable)._wrapped
                            ) as unknown as {
                              name: string;
                              value: number;
                              billable: boolean;
                              overdue: boolean;
                            }[]
                          }
                          renderItem={(item) => {
                            let color = !item.billable ? "blue" : "black";
                            if (item.overdue) {
                              color = "red";
                            }
                            return (
                              <List.Item>
                                <Text
                                  style={{
                                    flex: 1,
                                    color,
                                  }}
                                >
                                  {item.name}
                                </Text>
                                <Text
                                  strong
                                  style={{ paddingLeft: 20, color: "blue" }}
                                >{`$${Math.round(
                                  item.value
                                ).toLocaleString()}`}</Text>
                              </List.Item>
                            );
                          }}
                        />
                      }
                    />
                  </Col>
                  <Col span={8}>
                    <StatCard
                      title="GPM"
                      value={reportProjectsData.gpm.value}
                      previousValue={reportProjectsData.gpm.previousValue}
                      prefix="$"
                      popoverContent={
                        <List
                          size="small"
                          dataSource={
                            Object.values(
                              _.chain(reportProjectsData.gpm.list)
                                .sortBy((item) => item.overdue)
                                .sortBy((item) => !item.billable)._wrapped
                            ) as unknown as {
                              name: string;
                              value: [number, number];
                              billable: boolean;
                              overdue: boolean;
                            }[]
                          }
                          renderItem={(item) => (
                            <List.Item>
                              <Text
                                style={{
                                  flex: 1,
                                  color: getReportMilestoneColor(item),
                                }}
                              >
                                {item.name}
                              </Text>
                              <Text
                                strong
                                style={{ paddingLeft: 20 }}
                              >{`$${Math.round(
                                item.value[0]
                              ).toLocaleString()} - $
                          ${Math.round(item.value[1]).toLocaleString()} = $${
                                Math.round(item.value[0]) -
                                Math.round(item.value[1])
                              }`}</Text>
                            </List.Item>
                          )}
                        />
                      }
                    />
                  </Col>
                </Row>
              </>
            ) : null}
            <Title level={3}>Performance</Title>
            <Row gutter={16}>
              <Col span={8}>
                <StatCard
                  title="Active Projects"
                  value={reportProjectsData.activeProjects.value}
                  previousValue={
                    reportProjectsData.activeProjects.previousValue
                  }
                  precision={0}
                  popoverContent={
                    <List
                      size="small"
                      dataSource={
                        Object.values(
                          _.chain(reportProjectsData.activeProjects.list)
                            .sortBy((item) => item.overdue)
                            .sortBy((item) => !item.billable)._wrapped
                        ) as unknown as {
                          name: string;
                          billable: boolean;
                          overdue: boolean;
                        }[]
                      }
                      renderItem={(item) => (
                        <List.Item>
                          <Text
                            style={{
                              flex: 1,
                              color: getReportMilestoneColor(item),
                            }}
                          >
                            {item.name}
                          </Text>
                        </List.Item>
                      )}
                    />
                  }
                />
              </Col>
              <Col span={8}>
                <StatCard
                  title="Closed Projects"
                  value={reportProjectsData.closedProjects.value}
                  previousValue={
                    reportProjectsData.closedProjects.previousValue
                  }
                  precision={0}
                  popoverContent={
                    <List
                      size="small"
                      dataSource={
                        Object.values(
                          _.chain(reportProjectsData.closedProjects.list)
                            .sortBy((item) => item.overdue)
                            .sortBy((item) => !item.billable)._wrapped
                        ) as unknown as {
                          name: string;
                          billable: boolean;
                          overdue: boolean;
                        }[]
                      }
                      renderItem={(item) => (
                        <List.Item>
                          <Text
                            style={{
                              flex: 1,
                              color: getReportMilestoneColor(item),
                            }}
                          >
                            {item.name}
                          </Text>
                        </List.Item>
                      )}
                    />
                  }
                />
              </Col>
              <Col span={8}>
                {/* <StatCard
                  title="To Close Projects"
                  value={10}
                  previousValue={20}
                  precision={0}
                  popoverContent={
                    <List
                      size="small"
                      dataSource={projects_dummy}
                      renderItem={(item) => (
                        <List.Item>
                          <Text style={{ flex: 1 }}>{item.name}</Text>
                        </List.Item>
                      )}
                    />
                  }
                /> */}
              </Col>
              <Col span={8}>
                <StatCard
                  title="COM"
                  value={reportProjectsData.com.value * 100}
                  previousValue={reportProjectsData.com.previousValue * 100}
                  prefix={"%"}
                  popoverContent={
                    <List
                      size="small"
                      dataSource={
                        Object.values(
                          _.chain(reportProjectsData.com.list)
                            .sortBy((item) => item.overdue)
                            .sortBy((item) => !item.billable)._wrapped
                        ) as unknown as {
                          name: string;
                          value: number;
                          billable: boolean;
                          overdue: boolean;
                        }[]
                      }
                      renderItem={(item) => (
                        <List.Item>
                          <Text
                            style={{
                              flex: 1,
                              color: getReportMilestoneColor(item),
                            }}
                          >
                            {item.name}
                          </Text>
                          <Text
                            strong
                            style={{ paddingLeft: 20 }}
                          >{`${Math.round(
                            item.value * 100
                          ).toLocaleString()} %`}</Text>
                        </List.Item>
                      )}
                    />
                  }
                />
              </Col>
              <Col span={8}>
                <StatCard
                  title="Ratio"
                  value={reportProjectsData.ratio.value * 100}
                  previousValue={reportProjectsData.ratio.previousValue * 100}
                  prefix={"%"}
                  popoverContent={
                    <List
                      size="small"
                      dataSource={
                        Object.values(
                          _.chain(reportProjectsData.ratio.list)
                            .sortBy((item) => item.overdue)
                            .sortBy((item) => !item.billable)._wrapped
                        ) as unknown as {
                          name: string;
                          value: number;
                          billable: boolean;
                          overdue: boolean;
                        }[]
                      }
                      renderItem={(item) => (
                        <List.Item>
                          <Text
                            style={{
                              flex: 1,
                              color: getReportMilestoneColor(item),
                            }}
                          >
                            {item.name}
                          </Text>
                          <Text strong style={{ paddingLeft: 20 }}>{`${(
                            item.value * 100
                          ).toLocaleString()} %`}</Text>
                        </List.Item>
                      )}
                    />
                  }
                />
              </Col>
              <Col span={8}>
                <StatCard
                  title="SPI"
                  value={reportProjectsData.spi.value * 100}
                  previousValue={reportProjectsData.spi.previousValue * 100}
                  prefix={"%"}
                  popoverContent={
                    <List
                      size="small"
                      dataSource={
                        Object.values(
                          _.chain(reportProjectsData.spi.list)
                            .sortBy((item) => item.overdue)
                            .sortBy((item) => !item.billable)._wrapped
                        ) as unknown as {
                          name: string;
                          value: number;
                          billable: boolean;
                          overdue: boolean;
                        }[]
                      }
                      renderItem={(item) => (
                        <List.Item>
                          <Text
                            style={{
                              flex: 1,
                              color: getReportMilestoneColor(item),
                            }}
                          >
                            {item.name}
                          </Text>
                          <Text strong style={{ paddingLeft: 20 }}>{`${(
                            item.value * 100
                          ).toLocaleString()} %`}</Text>
                        </List.Item>
                      )}
                    />
                  }
                />
              </Col>
            </Row>
            <Card
              style={{
                minHeight: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Tabs type="card" defaultActiveKey="1">
                <TabPane tab="Active Milestones" key="2">
                  <MilestonesTableExtended
                    reportProjectsData={reportProjectsData}
                    isPM={false}
                    forSU={forSU}
                    title="Active Milestones"
                    type="active"
                  />
                </TabPane>
                <TabPane tab="Closed Milestones" key="3">
                  <MilestonesTableExtended
                    reportProjectsData={reportProjectsData}
                    isPM={false}
                    forSU={forSU}
                    title="Closed Milestones"
                    type="closed"
                  />
                </TabPane>
                <TabPane tab="To Close Milestones" key="1">
                  <MilestonesTableExtended
                    reportProjectsData={reportProjectsData}
                    isPM={false}
                    forSU={forSU}
                    title="To Close Milestones"
                    type="to_close"
                  />
                </TabPane>
              </Tabs>
              <ProjectsTable
                reportProjectsData={reportProjectsData}
                isPM={false}
                forSU={forSU}
                title="Projects Table"
              />
            </Card>
          </TabPane>
          <TabPane tab="Team" key="2">
            {/* Content for Team Tab */}
            <Title level={3}>Performance</Title>
            <Row gutter={16}>
              <Col span={8}>
                <StatCard
                  title="Team Size"
                  value={reportProjectsData.teamSize.value}
                  previousValue={reportProjectsData.teamSize.previousValue}
                  popoverContent={
                    <List
                      size="small"
                      dataSource={
                        Object.values(
                          reportProjectsData.teamSize.list
                        ) as unknown as { name: string; value: number }[]
                      }
                      renderItem={(item) => (
                        <List.Item>
                          <Text style={{ flex: 1 }}>{item.name}</Text>
                        </List.Item>
                      )}
                    />
                  }
                />
              </Col>
              <Col span={8}>
                <StatCard
                  title="Hours Tracked"
                  value={reportProjectsData.hoursTracked.value / 1000 / 60 / 60}
                  previousValue={
                    reportProjectsData.hoursTracked.previousValue /
                    1000 /
                    60 /
                    60
                  }
                  popoverContent={
                    <List
                      size="small"
                      dataSource={
                        Object.values(
                          reportProjectsData.hoursTracked.list
                        ) as unknown as { name: string; value: number }[]
                      }
                      renderItem={(item) =>
                        item.value ? (
                          <List.Item>
                            <Text style={{ flex: 1 }}>{item.name}</Text>
                            <Text
                              strong
                              style={{ paddingLeft: 20 }}
                            >{`${Math.round(
                              item.value / 1000 / 60 / 60
                            )} h`}</Text>
                          </List.Item>
                        ) : null
                      }
                    />
                  }
                />
              </Col>
              <Col span={8}>
                <StatCard
                  title="Tasks Closed"
                  value={reportProjectsData.taskClosed.value}
                  previousValue={reportProjectsData.taskClosed.previousValue}
                  popoverContent={
                    <List
                      size="small"
                      dataSource={
                        Object.values(
                          reportProjectsData.taskClosed.list
                        ) as unknown as { name: string; value: number }[]
                      }
                      renderItem={(item) => (
                        <List.Item>
                          <Text style={{ flex: 1 }}>{item.name}</Text>
                          <Text
                            strong
                            style={{ paddingLeft: 20 }}
                          >{`${item.value}`}</Text>
                        </List.Item>
                      )}
                    />
                  }
                />
              </Col>
            </Row>
            <Members reportProjectsData={reportProjectsData} />
          </TabPane>
        </Tabs>
      ) : null}
    </div>
  );
};

export { Reports };
