import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { ClickupUser } from '../../types/clickup/ClickupUser';

export interface UserData {
  clickup: ClickupUser;
  blocked: boolean;
  confirmed: boolean;
  createdAt: string;
  clickup_id: string;
  email: string;
  id: number;
  position: string;
  provider: string;
  updatedAt: string;
  username: string;
  jwt?: string | undefined;
}
export interface UserStateData {
  data?: UserData;
}

const initialState: UserStateData = {
  data: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    set: (state, action: PayloadAction<UserData>) => {   
      state.data = action.payload;
    },
    logout: (state, action: PayloadAction<undefined>) => {    
      state.data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function

export const $user_actions = userSlice.actions;

export const userSelector = (state: RootState): UserStateData => state.user;

export const userNameSelector = createSelector(
  userSelector,
  (state) => state.data?.username
);

export const userDataSelector = createSelector(
  userSelector,
  (state) => state.data
);

export default userSlice.reducer;
