
//MILESTONE
export const MILESTONE_NAME = 'milestoneName';
export const MILESTONE_TIMELINE = 'milestoneTimeline';
export const MILESTONE_STATUS = 'milestoneStatus';
export const MILESTONE_QA_TASKS = 'milestoneQaTasks';
export const MILESTONE_RATIO = 'milestoneRatio';
export const MILESTONE_E = 'MILESTONE_E';
export const MILESTONE_ET = 'milestoneET';
export const MILESTONE_EP = 'milestoneEP';
export const MILESTONE_SPENT = 'milestoneSpent';
export const MILESTONE_COM = 'milestoneCOM';
export const MILESTONE_CLEAN_HRS = 'milestoneCleanHrs';
export const MILESTONE_VELOCITY = 'milestoneVelocity';
export const MILESTONE_NPS = 'milestoneNPS';

//TASKS
export const TASK_NAME = 'taskName';
export const TASK_TIMELINE = 'taskTimeline';
export const TASK_STATUS = 'taskStatus';
export const TASK_DESCRIPTION = 'taskDescription';
export const TASK_ASSIGNEES = 'taskAssignees';
export const TASK_RATIO = 'taskRatio';

//MEMBERS
export const MEMBER_NAME = 'memberName';
export const MEMBER_ROLE = 'memberRole';
export const MEMBER_ACTIONS = 'memberActions';
export const AMOUNT_OF_MILESTONES = 'amountOfMilestones';
export const AMOUNT_OF_OPEN_TASKS = 'amountOfOpenTasks';
export const AMOUNT_OF_REVIEW_TASKS = 'amountOfReviewTasks';
export const AMOUNT_OF_TASKS_TO_CLOSE = 'amountOfTasksToClose';
export const LOGS = 'logs';
