import moment from 'moment';

export const PM_POSITIONS = ['PM', 'Project Manager', 'Creative Director']
export const NOT_DEV_POSITIONS = [
  ...['SU', 'Sales Manager', 'Office Manager', 'HR', 'Marketer', 'Delivery Manager'],
  ...PM_POSITIONS
]

export const getTimeConstants = () => {
  const initTC = {
    today: moment().startOf('day').valueOf(),
    yesterday: moment().startOf('day').valueOf() - 86400000,
    thisPeriod:
      moment().date() > 14
        ? moment().startOf('month').valueOf() + 1209600000
        : moment().startOf('month').valueOf(),
    lastPeriod:
      moment().date() > 14
        ? moment().startOf('month').valueOf()
        : moment().startOf('month').subtract(1, 'months').valueOf() +
          1209600000,
  };
 
  return initTC;
};

export const getHoursInPeriod = () => {
  
  const hoursPeriod = {
    today: 8,
    yesterday: 8,
    thisWeek: 40,
    thisPeriod:
      moment().date() > 14 ? (moment().daysInMonth() - 14) * 8 : 14 * 8,
    lastPeriod:
      moment().date() > 14
        ? 14 * 8
        : (moment().subtract(1, 'months').daysInMonth() - 14) * 8,
  };

  return hoursPeriod;
};

export const isPM = (position: any) => {
  return PM_POSITIONS.includes(position)
}

export const isDev = (position: any) => {
  return !NOT_DEV_POSITIONS.includes(position)
}
