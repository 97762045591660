import React, { useState } from 'react';
import moment from 'moment';
import { Progress, Typography, Row, Col } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';

const { Text, Link } = Typography;

const timeStatistic = (record) => {
  let percent = 0;
  let noTimes = true;
  let difference = 0;

  if (record.spent && record.estimate) {
    percent = Math.round((record.spent / record.estimate) * 100);

    if (record.spent > record.estimate) {
      difference = record.spent - record.estimate;
    } else {
      difference = record.estimate - record.spent;
    }
    noTimes = false;
  }

  return (
    <>
      {!noTimes ? (
        <div>
          <Row style={{ marginBottom: '-9px', fontSize: '12px' }}>
            <Col span={12}>
              <Text type='secondary'>
                {record.estimate
                  ? moment.utc(record.estimate).format('HH:mm')
                  : 0}
              </Text>
            </Col>
            <Col span={12}>
              <Text type='secondary'>
                {record.spent ? moment.utc(record.spent).format('HH:mm') : 0}
              </Text>
            </Col>
          </Row>
          {percent > 100 ? (
            <Progress
              percent={100 - (percent - 100)}
              size='small'
              status='exception'
              format={(percent) => `-${moment.utc(difference).format('HH:mm')}`}
            />
          ) : (
            <Progress
              percent={percent}
              size='small'
              format={(percent) => `${moment.utc(difference).format('HH:mm')}`}
            />
          )}
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <WarningTwoTone twoToneColor='#ff2c00' />
        </div>
      )}
    </>
  );
};

export default timeStatistic;
