import React, { useEffect } from "react";
import { Card, Space, Table, Typography, Tag } from "antd";
import { milestonesActiveSelector } from "../../storage/reducers/milestones.reducer";
import { projectsDataSelector } from "../../storage/reducers/projects.reducer";
import { useSelector } from "react-redux";
import _ from "underscore";
import { ExpandedMilestonesTable } from "./expandedMilestonesTable";
import DateStatistic from "../tableComponents/dateStatistic";
import RatioBar from "../tableComponents/ratioBar";
import moment from "moment";
import { ReportProjectsData } from "../../pages/reports";

const { Text, Link } = Typography;

interface ProjectsTableProps {
  title: string;
  toClose?: boolean;
  isPM?: boolean;
  clientID?: string;
  reportProjectsData?: ReportProjectsData;
  forSU?: boolean;
}

const ProjectsTable: React.FC<ProjectsTableProps> = ({
  title,
  toClose = false,
  isPM,
  clientID,
  reportProjectsData,
  forSU,
}) => {
  const milestones: any =
    reportProjectsData?.filteredMilestones ||
    useSelector(milestonesActiveSelector);
  const projects: any =
    reportProjectsData?.filteredProjects || useSelector(projectsDataSelector);

  const [projectsToShow, setProjectsToShow] = React.useState<any>([]);

  useEffect(() => {
    if (projects && milestones) {
      let updatedProjects = _.map(projects, (project) => {
        const projectMilestones = _.filter(milestones, (ms) =>
          project.milestones.includes(ms.id)
        );
        let sumET = 0;
        let dirtySpentSum = 0;
        let spentHoursSum = 0;
        let spentSum = 0;
        let sumEP = 0;
        let min_date = Infinity;
        let max_date = 0;
        let closedTasksEstimatesSum = 0;

        projectMilestones.forEach((ms) => {
          sumET += parseFloat(ms.ET) || 0;
          sumEP += parseFloat(ms.EP) || 0;
          dirtySpentSum += parseFloat(ms.spent?.dirty) || 0;
          spentHoursSum += parseFloat(ms.spent?.hours) || 0;
          spentSum += parseFloat(ms.spent?.price) || 0;

          const tempMinDate = Number(ms.start_date);
          const tempMaxDate = Number(ms.due_date);
          if (min_date > tempMinDate) {
            min_date = tempMinDate;
          }
          if (max_date < tempMaxDate) {
            max_date = tempMaxDate;
          }
          closedTasksEstimatesSum += ms.closedTasksEstimatesSum;
        });

        let currentDate = Date.now();

        // if project already finished
        if (currentDate > max_date) {
          currentDate = max_date;
        }

        const expectedClosedHours = (currentDate - min_date) / 1000 / 60 / 60;
        let SPI: number | null = closedTasksEstimatesSum / expectedClosedHours;

        if (!isFinite(SPI)) {
          SPI = null;
        }

        return {
          ...project,
          milestonesFields: {
            ET: sumET,
            EP: sumEP,
            spent: {
              dirty: dirtySpentSum,
              hours: spentHoursSum,
              price: spentSum,
            },
          },
          start_date: min_date,
          due_date: max_date,
          SPI,
        };
      });
      if (clientID) {
        updatedProjects = _.filter(
          updatedProjects,
          (p) => p.client?.id === clientID
        );
      }
      setProjectsToShow(updatedProjects);
    }
  }, [projects, milestones]);

  const suFields = forSU
    ? [
        {
          title: "Spent USD",
          dataIndex: "spentUsd",
          key: "spentUsd",
          align: "right",
          width: "100px",
          hidden: isPM,
          render: (et, record) =>
            record.milestonesFields.spent.price ? (
              <Text style={{ color: "#0231E8" }}>
                {"$ " +
                  Intl.NumberFormat().format(
                    Math.round(record.milestonesFields.spent.price)
                  )}
              </Text>
            ) : (
              ""
            ),
        },
        {
          title: "E. USD",
          dataIndex: "EP",
          key: "ep",
          align: "right",
          hidden: isPM,
          render: (ep, record) => (
            <Text style={{ color: "#0231E8" }}>
              {record.milestonesFields.EP
                ? "$ " +
                  Intl.NumberFormat().format(
                    Math.round(record.milestonesFields.EP)
                  )
                : null}
            </Text>
          ),
        },
      ]
    : [];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      render: (name, record) => {
        return (
          <Space size={-4} direction="vertical">
            <Link
              target="_blank"
              strong
              href={`https://app.clickup.com/2479620/t/${record.id}`}
            >
              <Text type="secondary">{name}</Text>
            </Link>
          </Space>
        );
      },
    },
    Table.EXPAND_COLUMN,
    {
      title: "Timeline",
      dataIndex: "time",
      key: "time",
      width: "180px",
      render: (time, record) => <DateStatistic record={record} />,
    },
    {
      title: "Ratio",
      dataIndex: "ratio",
      key: "ratio",
      width: "180px",
      render: (et, record) => (
        <RatioBar
          estimate={record.milestonesFields.ET}
          spent={record.milestonesFields.spent.dirty}
        />
      ),
    },
    {
      title: "Clean Hrs",
      dataIndex: "clean",
      key: "clean",
      width: "100px",
      render: (et, record) => Math.round(record.milestonesFields.spent.hours),
    },
    {
      title: "COM",
      dataIndex: "com",
      key: "com",
      width: "100px",
      render: (et, record) =>
        record.milestonesFields.spent.dirty
          ? `${Math.round(
              (record.milestonesFields.ET /
                record.milestonesFields.spent.dirty) *
                100
            )}%`
          : "",
    },
    ...suFields,
    {
      title: "LTV (Months)",
      dataIndex: "LTV",
      key: "ltv",
      align: "right",
      render: (ltv, record) => {
        const value =
          ltv?.min_date > 0 || ltv?.max_date > 0
            ? moment(ltv.max_date)
                .diff(moment(ltv.min_date), "months", true)
                .toFixed(1)
            : ((ltv || 0) / 1000 / 60 / 60 / 24 / 30).toFixed(1);
        return (
          <Text style={{ color: "#0231E8" }}>
            {(value as unknown as number) > 0 ? value : "-"}
          </Text>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: "140px",
      render: (status, mile) => {
        return (
          <Tag color={status.color} key={status}>
            {status.status?.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "SPI",
      dataIndex: "SPI",
      key: "SPI",
      align: "right",
      hidden: isPM,
      render: (item, record) => (
        <Text style={{ color: "#0231E8" }}>
          {item ? `${(item * 100).toFixed(2)}%` : "-"}
        </Text>
      ),
    },
    // @ts-ignore TODO: fix this
  ].filter((item) => !item.hidden);

  return (
    <>
      <Card size="small" title={title}>
        <Table
          size="small"
          pagination={false}
          columns={columns as any}
          scroll={{ y: 350 }}
          expandable={{
            expandedRowRender: (record) => (
              <ExpandedMilestonesTable
                isPM={isPM}
                milestonesIdArray={record.milestones}
                reportProjectsData={reportProjectsData}
                forSU={forSU}
              />
            ),
            rowExpandable: (record) => record.milestones.length,
          }}
          dataSource={projectsToShow}
          rowKey="id"
          // scroll={{ y: 240, x: 800 }}
          summary={(pageData) => {
            let totalET = 0;
            let totalEP = 0;
            let totalSpentDirty = 0;
            let totalSpentClean = 0;
            let totalSpentPrice = 0;
            let totalLTV = 0;
            let projectsWithLTV = 0;

            let spi = 0;
            let projectsWithSPI = 0;

            pageData.forEach((item) => {
              if (item.milestonesFields.spent) {
                if (item.milestonesFields.EP)
                  totalEP += parseFloat(item.milestonesFields.EP);
                if (item.milestonesFields.ET)
                  totalET += parseFloat(item.milestonesFields.ET);
                if (item.LTV && item.LTV > 0) {
                  totalLTV += parseFloat(item.LTV);
                  projectsWithLTV++;
                }
                if (item.SPI) {
                  spi += item.SPI;
                  projectsWithSPI++;
                }
                totalSpentDirty += item.milestonesFields.spent.dirty;
                totalSpentClean += item.milestonesFields.spent.hours;
                totalSpentPrice += item.milestonesFields.spent.price;
              }
            });

            const averageLTV = (
              (projectsWithLTV && totalLTV / projectsWithLTV) /
              1000 /
              60 /
              60 /
              24 /
              30
            ).toFixed(1);
            const averageSPI = (
              projectsWithSPI && spi / projectsWithSPI
            ).toFixed(2);

            const gpmUSD = totalEP - totalSpentPrice;
            const gpmPercent = totalEP
              ? Math.round((gpmUSD / totalEP) * 100)
              : 0;

            const COM = totalET / totalSpentDirty;

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    Summary <Text strong>({pageData.length})</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <Text strong>
                      {Intl.NumberFormat().format(Math.round(totalSpentDirty)) +
                        " "}
                      | {Intl.NumberFormat().format(Math.round(totalET))}
                    </Text>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={5}>
                    <Text strong>{Math.round(totalSpentClean)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    {COM && <Text strong>{Math.round(COM * 100)}%</Text>}
                  </Table.Summary.Cell>
                  {isPM || !forSU ? null : (
                    <>
                      <Table.Summary.Cell index={7} align="right">
                        <Text strong style={{ color: "#0231E8" }}>
                          {`$ ${String(Math.round(totalSpentPrice)).replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}`}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8} align="right">
                        <Text strong style={{ color: "#0231E8" }}>
                          {`$ ${String(Math.round(totalEP)).replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}`}
                        </Text>
                      </Table.Summary.Cell>
                    </>
                  )}
                  <Table.Summary.Cell index={8} align="right">
                    <Text strong style={{ color: "#0231E8" }}>
                      {Math.round(totalLTV / 1000 / 60 / 60 / 24 / 30)}
                    </Text>
                    <br />
                    <Text strong style={{ color: "#0231E8" }}>
                      AVG: {averageLTV}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9}></Table.Summary.Cell>
                  <Table.Summary.Cell index={10} align="right">
                    <Text strong style={{ color: "#0231E8" }}>
                      AVG: {((averageSPI as any as number) * 100).toFixed(2)}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Card>
    </>
  );
};

export { ProjectsTable };
