import React from 'react';
import { Card, Table } from 'antd';
import getColumns from './getColumns';
import {
  TASK_ASSIGNEES,
  TASK_DESCRIPTION,
  TASK_NAME,
  TASK_RATIO,
  TASK_STATUS,
  TASK_TIMELINE,
} from '../../constants/columnConstants';
import Task from '../../types/Task';
import { isDev } from '../../helpers';
interface Tasks {
  [index: number]: Task;
}

interface TaskTableProps {
  userPosition: string;
  tasks: Tasks | any
}

const getUserColumns = (userPosition: string) => {
  if (isDev(userPosition)) {
    return getColumns([
      TASK_NAME,
      TASK_RATIO,
      TASK_DESCRIPTION,
      TASK_STATUS,
    ]);
  }
  if (userPosition === 'QA') {
    return getColumns([
      TASK_NAME,
      TASK_DESCRIPTION,
      TASK_STATUS,
      TASK_ASSIGNEES,
    ]);
  }
};

const TasksTable: React.FC<TaskTableProps> = (props) => {
  const { tasks, userPosition } = props;

  return (
    <>
      <Card size='small' title='My tasks'>
        <Table
          size='small'
          pagination={false}
          columns={getUserColumns(userPosition)}
          dataSource={tasks}
          rowKey='id'
        />
      </Card>
    </>
  );
};

export default TasksTable;
