import { Table } from 'antd';
import {
  MILESTONE_RATIO,
  TASK_ASSIGNEES,
  TASK_DESCRIPTION,
  TASK_NAME,
  TASK_RATIO,
  TASK_STATUS,
  TASK_TIMELINE,
} from '../../constants/columnConstants';
import Task from '../../types/Task';
import getColumns from './getColumns';
import { isDev, isPM } from '../../helpers';

interface Tasks {
  [index: number]: Task;
}
interface Record {
  id: string;
  tasks: any | Tasks;
  [key: string]: string;
  custom_fields: any;
}

const getUserColumns = (userPosition: string) => {
  if (isDev(userPosition)) {
    return getColumns([TASK_NAME, TASK_RATIO, TASK_STATUS]);
  } else if (userPosition === 'QA') {
    return getColumns([
      TASK_NAME,
      TASK_DESCRIPTION,
      TASK_STATUS,
      TASK_ASSIGNEES,
    ]);
  } else if (isPM(userPosition)) {
    return getColumns([
      TASK_NAME,
      TASK_ASSIGNEES,
      TASK_RATIO,
      TASK_STATUS,      
    ]);
  }
};

const expandedTasksTable = (record: Record, userPosition: string) => {
  const data: Task[] = [];
  record.tasks.length &&
    record.tasks.forEach((task, index) => {
      data.push({
        key: index,
        ...task,
        taskName: task.name ? `${task.name.substring(0, 40)}...` : '',
      });
     
    });

  return (
    <Table
      columns={getUserColumns(userPosition)}
      dataSource={data}
      pagination={false}
    />
  );
};

export default expandedTasksTable;
