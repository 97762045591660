import React from 'react';
import moment from 'moment';
import { Progress, Typography, Row, Col, Space } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';

const { Text, Link } = Typography;

const DateStatistic = ({ record }) => {
  let progress = 0;
  let noDates = true;
  let differenceToCurrent = 0;

  if ((record.start_date || record.date_created) && record.due_date) {
    const curDate = moment();
    const startDate = moment.unix((record.start_date || record.date_created) / 1000);
    const dueDate = moment.unix(record.due_date/1000);
    differenceToCurrent = dueDate.diff(curDate, 'days')
    const differenceFromStart = startDate.diff(curDate, 'days')
    const differenceTimeline = dueDate.diff(startDate, 'days')

    progress = Math.round(Math.abs(differenceFromStart / differenceTimeline) * 100)
    
    noDates = false
  }

  return ( 
    !noDates ? 
      <div>
        <Row style={{ marginBottom: '-9px', fontSize: '12px', justifyContent: 'space-between' }}>
          {/* <Space block> */}
            <Col>
              <Text type="secondary">
                {
                  (record.start_date || record.date_created) ?
                    moment(+record.start_date || +record.date_created).format('DD MMM')
                    :
                    0
                }
              </Text>
            </Col>
            <Col>
              <Text type="secondary">{record.due_date ? moment(+record.due_date).format('DD MMM') : 0}</Text>
            </Col>
          {/* </Space> */}
        </Row>
        {progress > 100 
            ? 
          <Progress 
            percent={100 - (progress - 100)} 
            size='small' 
            status="exception" 
            format={percent => `${differenceToCurrent}d`}/>
            :       
          <Progress percent={progress} size='small' format={percent => `${differenceToCurrent}d`}/>
        } 
      </div>
    : <div style={{ textAlign: 'center' }}><WarningTwoTone twoToneColor='#ff2c00'/></div>);
};

export default DateStatistic;
